import React from 'react'
import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { RoleLevel } from "@common/domain/entities/Role";

export const ProtectedRoute = ({ children, level = RoleLevel.Editor }) => {
  const { user } = useAuth();
  if (!user || level > user?.role.level ) {
    // user is not authenticated or the role level is not enough
    return <Navigate to="/login" />;
  }
  return children;
};