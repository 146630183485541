import SectorRepository from "@common/domain/data/SectorRepository";
import Sector from "@common/domain/entities/Sector";
import User from "@common/domain/entities/User";
import { v4 as uuidv4 } from 'uuid';
import { sectors } from "./InitData";

export default class MockSectorRepository implements SectorRepository{

    private static sectors: Sector[] = sectors;

    async saveSector(user: User, sector: Sector): Promise<Sector> {
        if (!sector.id){
            sector.id = uuidv4();
            MockSectorRepository.sectors.push(sector);
            return sector;
        }else{
            const record = MockSectorRepository.sectors.find(record => record.id === sector.id);
            if (record){
                record.name = sector.name;
                record.status = sector.status;
            }
            return record as Sector;
        }
    }
    async getSectors(user: User): Promise<Sector[]> {
        return MockSectorRepository.sectors;
    }
    async deleteSector(user: User, sector: Sector): Promise<boolean> {
        const record = MockSectorRepository.sectors.find(record => record.id === sector.id);
        if (record){
            MockSectorRepository.sectors = MockSectorRepository.sectors.filter(record => record.id !== sector.id)
            return true;
        }
        return false;
    }

}