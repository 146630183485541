import React from 'react'
import { lazy } from 'react';

// project imports
import MainLayout from '../theme/layout/MainLayout';
import Loadable from '../theme/widgets/Loadable';
import { ProtectedRoute } from './ProtectedRoute';
import { RoleLevel } from '@common/domain/entities/Role';

// dashboard routing
const Users = Loadable(lazy(() => import('../pages/administrator/Users')));
const Home = Loadable(lazy(() => import('../pages/dashboard/Default')));


// ==============================|| MAIN ROUTING ||============================== //

const AdministratorRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    { path: 'users', element: <ProtectedRoute level={RoleLevel.Administrator}><Users /></ProtectedRoute> },
  ]
};

export default AdministratorRoutes;