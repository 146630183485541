import RoleRepository from "@common/domain/data/RoleRepository";
import Role from "@common/domain/entities/Role";
import User from "@common/domain/entities/User";
import { roles } from "./InitData";

export default class MockRoleRepository implements RoleRepository{

    async getRoles(user: User): Promise<Role[]> {
        return roles;
    }
    
}