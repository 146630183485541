const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/home',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    gridSpacing: 3,
    drawerWidth: 260,
    appDrawerWidth: 320
  };
  
export default config;  