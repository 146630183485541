// assets
import { IconHome } from '@tabler/icons';
import { RoleLevel } from '@common/domain/entities/Role';

// constant
const icons = { IconHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const home = {
  id: 'home',
  type: 'group',
  level: RoleLevel.Editor,
  children: [{
    id: 'default',
    title: 'Home',
    type: 'item',
    url: '/home',
    icon: icons.IconHome,
    breadcrumbs: false
  }]
};

export default home;
