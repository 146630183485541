import React from 'react'
import { createContext, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { login as sessionLogin } from "../redux/actions/session";
import { Status } from "../redux/reducers/session";
import { RoleLevel } from "@common/domain/entities/Role";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const loggedUser = useSelector((state) => state.session.loggedUser);
  const loginStatus = useSelector((state) => state.session.loginStatus);

  useEffect(()=>{
    if (loginStatus === Status.COMPLETE){
      setUser(loggedUser);
      if (loggedUser.role.level <= RoleLevel.Member){
        navigate("/");
      }else{
        navigate("/companies");
      }
    }
  }, [loggedUser, loginStatus]);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    dispatch(sessionLogin(data.username, data.password));
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};