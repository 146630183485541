import React from 'react'
import { useRoutes } from 'react-router-dom';

// routes
import AdministratorRoutes from './AdministratorRoutes';
import EditorRoutes from './EditorRoutes';
import SessionRoutes from './SessionRoutes';
import FrontRoutes from './FrontRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([FrontRoutes, SessionRoutes, AdministratorRoutes, EditorRoutes]);
}