import PermissionTypeRepository from "@common/domain/data/PermissionTypeRepository";
import PermissionType from "@common/domain/entities/PermissionType";
import User from "@common/domain/entities/User";
import { permissionTypes } from "./InitData";

export default class MockPermissionTypeRepository implements PermissionTypeRepository{
    async getPermissionTypes(user: User): Promise<PermissionType[]> {
        return permissionTypes;
    }
    
}