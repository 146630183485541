import React from 'react'
export default function RoundedTable({border, borderRadius, children}:{border:string, borderRadius: string, children:any}) {
    return (
        <div className="RoundedTable">
            <style>{`
                .RoundedTable table {
                    /* Change these properties */
                    --border: ${border};
                    border-radius: ${borderRadius};
                   
                    /* Don't change these properties */
                    border-spacing: 0;
                    border-collapse: separate;
                    border: var(--border);
                    overflow: hidden;
                }
                   
                /* Apply a border to the right of all but the last column */
                .RoundedTable table th:not(:last-child),
                .RoundedTable table td:not(:last-child) {
                    border-right: var(--border);
                }
                   
                /* Apply a border to the bottom of all but the last row */
                .RoundedTable table > thead > tr:not(:last-child)>th,
                .RoundedTable table > thead > tr:not(:last-child)>td,
                .RoundedTable table > tbody > tr:not(:last-child)>th,
                .RoundedTable table > tbody > tr:not(:last-child)>td,
                .RoundedTable table > tfoot > tr:not(:last-child)>th,
                .RoundedTable table > tfoot > tr:not(:last-child)>td,
                .RoundedTable table > tr:not(:last-child)>td,
                .RoundedTable table > tr:not(:last-child)>th,
                .RoundedTable table > thead:not(:last-child),
                .RoundedTable table > tbody:not(:last-child),
                .RoundedTable table > tfoot:not(:last-child) {
                    border-bottom: var(--border);
                }
            `}</style>
            <table>
                {children}
            </table>
            <br/>
        </div>
    )
};