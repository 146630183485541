import { ActionTypes } from "../actions/session";

export const Status = {
    INIT: 'initial',
    LOADING: 'loading',
    COMPLETE: 'complete',
    ERROR: 'error',
};
  
export const sessionState = {
    loggedUser: null,
    loginStatus: Status.INIT,
    loginError: null,
    menuVisible: true,
    menuSelected: [],
    passwordUpdateStatus: Status.INIT,
    passwordUpdateError: null,
    requestPasswordUpdateStatus: Status.INIT,
    requestPasswordUpdateError: null
};

export default function sessionReducer(state = sessionState, action: any) {
    switch (action.type) {
        case ActionTypes.LOGIN_COMPLETE: {
            return {
                ...state,
                loggedUser: action.payload,
                loginStatus: Status.COMPLETE,
                loginError: null
            }
        }
        case ActionTypes.LOGIN_ERROR: {
            return {
                ...state,
                loginStatus: Status.ERROR,
                loginError: action.payload
            }
        }
        case ActionTypes.LOGIN_PROCESSING: {
            return {
                ...state,
                loginStatus: Status.LOADING
            }
        }
        case ActionTypes.MENU_SELECTED: {
            return {
                ...state,
                menuSelected: [action.payload]
            }
        }
        case ActionTypes.MENU_VISIBLE: {
            return {
                ...state,
                menuVisible: action.payload
            }
        }
        case ActionTypes.PASSWORD_UPDATE_COMPLETE: {
            return {
                ...state,
                passwordUpdateStatus: Status.COMPLETE,
                passwordUpdateError: null
            }
        }
        case ActionTypes.PASSWORD_UPDATE_ERROR: {
            return {
                ...state,
                passwordUpdateStatus: Status.ERROR,
                passwordUpdateError: action.payload
            }
        }
        case ActionTypes.PASSWORD_UPDATE_PROCESSING: {
            return {
                ...state,
                passwordUpdateStatus: Status.LOADING
            }
        }
        case ActionTypes.REQUEST_PASSWORD_UPDATE_COMPLETE: {
            return {
                ...state,
                requestPasswordUpdateStatus: Status.COMPLETE,
                requestPasswordUpdateError: null
            }
        }
        case ActionTypes.REQUEST_PASSWORD_UPDATE_ERROR: {
            return {
                ...state,
                requestPasswordUpdateStatus: Status.ERROR,
                requestPasswordUpdateError: action.payload
            }
        }
        case ActionTypes.REQUEST_PASSWORD_UPDATE_PROCESSING: {
            return {
                ...state,
                requestPasswordUpdateStatus: Status.LOADING
            }
        }
        default:
            return state
    }
}