export enum RoleLevel {
    Guest,
    Member,
    Editor,
    Administrator,
    SuperAdministrator
}
export type Role = {
    id?: string;
    name: string;
    description: string;
    level: RoleLevel;
}
export default Role;