import UserRepository from '../data/UserRepository';
import User from '../entities/User';

export default class SessionInteractor {

    private userRepository: UserRepository;

    constructor(userRepository: UserRepository){
        this.userRepository = userRepository;
    }

    public async login(username: string, password: string): Promise<User | undefined> {
        return this.userRepository.login(username, password);
    }

    public async requestPasswordUpdate(email: string): Promise<boolean> {
        return this.userRepository.requestPasswordUpdate(email);
    }

    public async passwordUpdate(username: string, password: string, otp: string): Promise<User | undefined> {
        return this.userRepository.passwordUpdate(username, password, otp);
    }
}