import SessionInteractor from "@common/domain/interactors/SessionInteractor";

export enum ActionTypes {
    LOGIN_PROCESSING = '@session/LOGIN_PROCESSING',
    LOGIN_COMPLETE = '@session/LOGIN_COMPLETE',
    LOGIN_ERROR = '@session/LOGIN_ERROR',
    MENU_VISIBLE = '@session/MENU_VISIBLE',
    MENU_SELECTED = '@session/MENU_SELECTED',
    REQUEST_PASSWORD_UPDATE_PROCESSING = '@session/REQUEST_PASSWORD_UPDATE_PROCESSING',
    REQUEST_PASSWORD_UPDATE_COMPLETE = '@session/REQUEST_PASSWORD_UPDATE_COMPLETE',
    REQUEST_PASSWORD_UPDATE_ERROR = '@session/REQUEST_PASSWORD_UPDATE_ERROR',
    PASSWORD_UPDATE_PROCESSING = '@session/PASSWORD_UPDATE_PROCESSING',
    PASSWORD_UPDATE_COMPLETE = '@session/PASSWORD_UPDATE_COMPLETE',
    PASSWORD_UPDATE_ERROR = '@session/PASSWORD_UPDATE_ERROR'
};

export const menuSelect = (menuItem: string) => {
    return async (dispatch: any) => {
        dispatch({ type: ActionTypes.MENU_SELECTED, payload: menuItem});
    };
};

export const menuVisible = (visibility: true) => {
    return async (dispatch: any) => {
        dispatch({ type: ActionTypes.MENU_VISIBLE, payload: visibility});
    };
};

export const login = (username: string, password: string) => {
    return async (dispatch: any, getState: any, deps: { sessionInteractor: SessionInteractor }) => {
        dispatch({ type: ActionTypes.LOGIN_PROCESSING});
        try{
            dispatch({ type: ActionTypes.LOGIN_COMPLETE, payload: await deps.sessionInteractor.login(username, password)});
        }catch(error){
            dispatch({ type: ActionTypes.LOGIN_ERROR, payload: error});
        }
    };
};

export const requestPasswordUpdate = (email: string) => {
    return async (dispatch: any, getState: any, deps: { sessionInteractor: SessionInteractor }) => {
        dispatch({ type: ActionTypes.REQUEST_PASSWORD_UPDATE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.REQUEST_PASSWORD_UPDATE_COMPLETE, payload: await deps.sessionInteractor.requestPasswordUpdate(email)});
        }catch(error){
            dispatch({ type: ActionTypes.REQUEST_PASSWORD_UPDATE_ERROR, payload: error});
        }
    };
};

export const passwordUpdate = (username: string, password: string, otp: string) => {
    return async (dispatch: any, getState: any, deps: { sessionInteractor: SessionInteractor }) => {
        dispatch({ type: ActionTypes.PASSWORD_UPDATE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.PASSWORD_UPDATE_COMPLETE, payload: await deps.sessionInteractor.passwordUpdate(username, password, otp)});
        }catch(error){
            dispatch({ type: ActionTypes.PASSWORD_UPDATE_ERROR, payload: error});
        }
    };
};