import Permission from "@common/domain/entities/Permission";
import User from "@common/domain/entities/User";
import AdministratorInteractor from "@common/domain/interactors/AdministratorInteractor"

export enum ActionTypes {
    ORGANIZATIONS_LOAD_PROCESSING = '@administrator/ORGANIZATION_LOAD_PROCESSING',
    ORGANIZATIONS_LOAD_COMPLETE = '@administrator/ORGANIZATION_LOAD_COMPLETE',
    ORGANIZATIONS_LOAD_ERROR = '@administrator/ORGANIZATION_LOAD_ERROR',
    PERMISSION_SAVE_PROCESSING = '@administrator/PERMISSION_SAVE_PROCESSING',
    PERMISSION_SAVE_COMPLETE = '@administrator/PERMISSION_SAVE_COMPLETE',
    PERMISSION_SAVE_ERROR = '@administrator/PERMISSION_SAVE_ERROR',
    PERMISSIONS_LOAD_PROCESSING = '@administrator/PERMISSIONS_LOAD_PROCESSING',
    PERMISSIONS_LOAD_COMPLETE = '@administrator/PERMISSIONS_LOAD_COMPLETE',
    PERMISSIONS_LOAD_ERROR = '@administrator/PERMISSIONS_LOAD_ERROR',
    PERMISSIONTYPES_LOAD_PROCESSING = '@administrator/PERMISSIONTYPES_LOAD_PROCESSING',
    PERMISSIONTYPES_LOAD_COMPLETE = '@administrator/PERMISSIONTYPES_LOAD_COMPLETE',
    PERMISSIONTYPES_LOAD_ERROR = '@administrator/PERMISSIONTYPES_LOAD_ERROR',
    ROLES_LOAD_PROCESSING = '@administrator/ROLES_LOAD_PROCESSING',
    ROLES_LOAD_COMPLETE = '@administrator/ROLES_LOAD_COMPLETE',
    ROLES_LOAD_ERROR = '@administrator/ROLES_LOAD_ERROR',
    USER_SAVE_PROCESSING = '@administrator/USER_SAVE_PROCESSING',
    USER_SAVE_COMPLETE = '@administrator/USER_SAVE_COMPLETE',
    USER_SAVE_ERROR = '@administrator/USER_SAVE_ERROR',
    USER_DELETE_PROCESSING = '@administrator/USER_DELETE_PROCESSING',
    USER_DELETE_COMPLETE = '@administrator/USER_DELETE_COMPLETE',
    USER_DELETE_ERROR = '@administrator/USER_DELETE_ERROR',
    USERS_LOAD_PROCESSING = '@administrator/USERS_LOAD_PROCESSING',
    USERS_LOAD_COMPLETE = '@administrator/USERS_LOAD_COMPLETE',
    USERS_LOAD_ERROR = '@administrator/USERS_LOAD_ERROR'
};

export const loadOrganizations = (user: User) => {
    return async (dispatch: any, getState: any, deps: { administratorInteractor: AdministratorInteractor }) => {
        dispatch({ type: ActionTypes.ORGANIZATIONS_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.ORGANIZATIONS_LOAD_COMPLETE, payload: await deps.administratorInteractor.getOrganizations(user)});
        }catch(error){
            dispatch({ type: ActionTypes.ORGANIZATIONS_LOAD_ERROR, payload: error});
        }
    };
}

export const loadUsers = (user: User) => {
    return async (dispatch: any, getState: any, deps: { administratorInteractor: AdministratorInteractor }) => {
        dispatch({ type: ActionTypes.USERS_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.USERS_LOAD_COMPLETE, payload: await deps.administratorInteractor.getUsers(user)});
        }catch(error){
            dispatch({ type: ActionTypes.USERS_LOAD_ERROR, payload: error});
        }
    }
}

export const saveUser = (user: User, userToSave: User) => {
    return async (dispatch: any, getState: any, deps: { administratorInteractor: AdministratorInteractor }) => {
        dispatch({ type: ActionTypes.USER_SAVE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.USER_SAVE_COMPLETE, payload: await deps.administratorInteractor.saveUser(user, userToSave)});
        }catch(error){
            dispatch({ type: ActionTypes.USER_SAVE_ERROR, payload: error});
        }
    }
}

export const deleteUser = (user: User, userToDelete: User) => {
    return async (dispatch: any, getState: any, deps: { administratorInteractor: AdministratorInteractor }) => {
        dispatch({ type: ActionTypes.USER_DELETE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.USER_DELETE_COMPLETE, payload: await deps.administratorInteractor.deleteUser(user, userToDelete)});
        }catch(error){
            dispatch({ type: ActionTypes.USER_DELETE_ERROR, payload: error});
        }
    }
}

export const loadRoles = (user: User) => {
    return async (dispatch: any, getState: any, deps: { administratorInteractor: AdministratorInteractor }) => {
        dispatch({ type: ActionTypes.ROLES_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.ROLES_LOAD_COMPLETE, payload: await deps.administratorInteractor.getRoles(user)});
        }catch(error){
            dispatch({ type: ActionTypes.ROLES_LOAD_ERROR, payload: error});
        }
    }
}