import CompanyRepository from "../data/CompanyRepository";
import HubRepository from "../data/HubRepository";
import HubTypeRepository from "../data/HubTypeRepository";
import NewsRepository from "../data/NewsRepository";
import SectorRepository from "../data/SectorRepository";
import TagRepository from "../data/TagRepository";
import Company from "../entities/Company";
import Hub from "../entities/Hub";
import HubType from "../entities/HubType";
import News from "../entities/News";
import Sector from "../entities/Sector";
import Tag from "../entities/Tag";
import User from "../entities/User";

export default class RequestorInteractor {
    
    private hubTypeRepository: HubTypeRepository;
    private sectorRepository: SectorRepository;
    private tagRepository: TagRepository;
    private hubRepository: HubRepository;
    private companyRepository: CompanyRepository;
    private newsRepository: NewsRepository;


    constructor(hubTypeRepository: HubTypeRepository, sectorRepository: SectorRepository,tagRepository: TagRepository,
        hubRepository: HubRepository, companyRepository: CompanyRepository, newsRepository: NewsRepository) {
        this.hubTypeRepository = hubTypeRepository;
        this.sectorRepository = sectorRepository;
        this.tagRepository = tagRepository;
        this.hubRepository= hubRepository;
        this.companyRepository = companyRepository;
        this.newsRepository = newsRepository;
    }

    public async saveHubType(user: User, hubType: HubType): Promise<HubType>{
        return this.hubTypeRepository.saveHubType(user, hubType);
    }
    public async getHubTypes(user: User): Promise<HubType[]>{
        return this.hubTypeRepository.getHubTypes(user);
    }
    public async deleteHubType(user: User, hubType: HubType): Promise<boolean>{
        return this.hubTypeRepository.deleteHubType(user, hubType);
    }

    public async saveSector(user: User, sector: Sector): Promise<Sector>{
        return this.sectorRepository.saveSector(user, sector);
    }
    public async getSectors(user: User): Promise<Sector[]>{
        return this.sectorRepository.getSectors(user);
    }
    public async deleteSector(user: User, sector: Sector): Promise<boolean>{
        return this.sectorRepository.deleteSector(user, sector);
    }

    public async saveTag(user: User, tag: Tag): Promise<Tag>{
        return this.tagRepository.saveTag(user, tag);
    }
    public async getTags(user: User): Promise<Tag[]>{
        return this.tagRepository.getTags(user);
    }
    public async deleteTag(user: User, tag: Tag): Promise<boolean>{
        return this.tagRepository.deleteTag(user, tag);
    }
    public async saveHub(user: User, hub: Hub): Promise<Hub>{
        return this.hubRepository.saveHub(user, hub);
    }
    public async getHubs(user: User, name:string, hubType?: HubType,sector?: Sector): Promise<Hub[]>{
        return this.hubRepository.getHubs(user,name,hubType,sector);
    }
    public async deleteHub(user: User, hub: Hub): Promise<boolean>{
        return this.hubRepository.deleteHub(user, hub);
    }
    public async getHubById(hubId: string): Promise<Hub | undefined> {
        console.log("RequestorInteractor getHubById", hubId);
        return this.hubRepository.getHubById(hubId);
    }
    public async saveCompany(user: User, company: Company): Promise<Company>{
        return this.companyRepository.saveCompany(user, company);
    }
    public async getCompanies(user: User,name?: string, sector?: Sector, isstartup?:boolean, isinvestor?:boolean,state?:string): Promise<Company[]>{
        console.log("getCompanies", name);
        return this.companyRepository.getCompanies(user, name, sector,isstartup, isinvestor,state);
    }
    public async deleteCompany(user: User, company: Company): Promise<boolean>{
        return this.companyRepository.deleteCompany(user, company);
    }
    public async getCompanyById(companyId: string): Promise<Company | undefined> {
        console.log("RequestorInteractor getCompanyById", companyId);
        return this.companyRepository.getCompanyById(companyId);
    }
    public async saveNews(user: User, news: News): Promise<News>{
        return this.newsRepository.saveNews(user, news);
    }
    public async getNews(): Promise<News[]>{
        return this.newsRepository.getNews();
    }
    public async deleteNews(user: User, news: News): Promise<boolean>{
        return this.newsRepository.deleteNews(user, news);
    }
}