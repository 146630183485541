import { combineReducers } from 'redux';

import administratorReducer from './administrator';
import requestorReducer from './requestor';
import sessionReducer from './session';

const rootReducer = combineReducers({
  administrator: administratorReducer,
  requestor: requestorReducer,
  session: sessionReducer
});

export default rootReducer;