//import AdministratorInteractor from "@common//domain/interactors/AdministratorInteractor';
import AdministratorInteractor from "@common/domain/interactors/AdministratorInteractor";
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';

import { createStore, applyMiddleware, compose } from 'redux';
import EditorInteractor from "@common/domain/interactors/RequestorInteractor";
import SessionInteractor from "@common/domain/interactors/SessionInteractor";
import OrganizationRepository from "@common/domain/data/OrganizationRepository";
import PermissionRepository from "@common/domain/data/PermissionRepository";
import PermissionTypeRepository from "@common/domain/data/PermissionTypeRepository";
import RoleRepository from "@common/domain/data/RoleRepository";
import UserRepository from "@common/domain/data/UserRepository";
import APIOrganizationRepository from '../../data-amazon_api_gateway/APIOrganizationRepository';
import APIPermissionRepository from '../../data-amazon_api_gateway/APIPermissionRepository';
import APIPermissionTypeRepository from '../../data-amazon_api_gateway/APIPermissionTypeRepository';
import APIRoleRepository from '../../data-amazon_api_gateway/APIRoleRepository';
import APIUserRepository from '../../data-amazon_api_gateway/APIUserRepository';
import MockOrganizationRepository from '../../data-mock/MockOrganizationRepository';
import MockPermissionRepository from '../../data-mock/MockPermissionRepository';
import MockPermissionTypeRepository from '../../data-mock/MockPermissionTypeRepository';
import MockRoleRepository from '../../data-mock/MockRoleRepository';
import MockUserRepository from '../../data-mock/MockUserRepository';
import MockHubTypeRepository from '../../data-mock/MockHubTypeRepository';
import HubTypeRepository from "@common/domain/data/HubTypeRepository";
import SectorRepository from "@common/domain/data/SectorRepository";
import MockSectorRepository from '../../data-mock/MockSectorRepository';
import MockTagRepository from '../../data-mock/MockTagRepository';
import TagRepository from "@common/domain/data/TagRepository";
import HubRepository from "@common/domain/data/HubRepository";
import MockHubRepository from "../../data-mock/MockHubRepository";
import CompanyRepository from "@common/domain/data/CompanyRepository";
import MockCompanyRepository from '../../data-mock/MockCompanyRepository';
import NewsRepository from "@common/domain/data/NewsRepository";
import MockNewsRepository from '../../data-mock/MockNewsRepository';

export enum DataImplementation {
    AMAZON_API_GATEWAY,
    MOCK
};

export default function configureStore (dataImplementation: DataImplementation) {
    let organizationRepository: OrganizationRepository;
    let permissionRepository: PermissionRepository;
    let permissionTypeRepository: PermissionTypeRepository;
    let roleRepository: RoleRepository;
    let userRepository: UserRepository;
    let hubTypeRepository: HubTypeRepository;
    let sectorRepository: SectorRepository;
    let tagRepository: TagRepository;
    let hubRepository: HubRepository;
    let companyRepository: CompanyRepository
    let newsRepository: NewsRepository;
    switch (dataImplementation){
        case DataImplementation.AMAZON_API_GATEWAY: {
            organizationRepository = new APIOrganizationRepository();
            permissionRepository = new APIPermissionRepository();
            permissionTypeRepository = new APIPermissionTypeRepository();
            roleRepository = new APIRoleRepository();
            userRepository = new APIUserRepository();
            hubTypeRepository = new MockHubTypeRepository();
            sectorRepository = new MockSectorRepository();
            tagRepository = new MockTagRepository();
            hubRepository = new MockHubRepository();
            companyRepository = new MockCompanyRepository();
            newsRepository = new MockNewsRepository();
            break;
        }
        case DataImplementation.MOCK: {
            organizationRepository = new MockOrganizationRepository();
            permissionRepository = new MockPermissionRepository();
            permissionTypeRepository = new MockPermissionTypeRepository();
            roleRepository = new MockRoleRepository();
            userRepository = new MockUserRepository();
            hubTypeRepository = new MockHubTypeRepository();
            sectorRepository = new MockSectorRepository();
            tagRepository = new MockTagRepository();
            hubRepository = new MockHubRepository();
            companyRepository = new MockCompanyRepository();
            newsRepository = new MockNewsRepository();
            break;
        }   
    }
    const administratorInteractor = new AdministratorInteractor(organizationRepository, permissionRepository, permissionTypeRepository, roleRepository, userRepository);
    const requestorInteractor = new EditorInteractor(hubTypeRepository, sectorRepository, tagRepository, hubRepository, companyRepository,newsRepository);
    const sessionInteractor = new SessionInteractor(userRepository);
    
    const middlewares = [
        thunkMiddleware.withExtraArgument({
            administratorInteractor,
            requestorInteractor,
            sessionInteractor
        })
    ];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer];
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const composedEnhancers: any = composeEnhancers(...enhancers);
    return createStore(rootReducer, composedEnhancers);
};