import Organization from "@common/domain/entities/Organization";
import User from "@common/domain/entities/User";
import Role from "@common/domain/entities/Role";
import PermissionType from "@common/domain/entities/PermissionType";
import Permission from "@common/domain/entities/Permission";
import { ActionTypes } from "../actions/administrator";

export const Status = {
    INIT: 'initial',
    LOADING: 'loading',
    COMPLETE: 'complete',
    ERROR: 'error',
};
  
const initialState = {
    organizationsLoadStatus: Status.INIT,
    organizations: [] as Organization[],
    organizationsLoadError: null,
    permissionsLoadStatus: Status.INIT,
    permissions: [] as Permission[],
    permissionsLoadError: null,
    permissionTypesLoadStatus: Status.INIT,
    permissionTypes: [] as PermissionType[],
    permissionTypesLoadError: null,
    permissionSaveStatus: Status.INIT,
    permissionSaveError: null,
    rolesLoadStatus: Status.INIT,
    roles: [] as Role[],
    rolesLoadError: null,
    usersLoadStatus: Status.INIT,
    users: [] as User[],
    usersLoadError: null,
    userSaveStatus: Status.INIT,
    userSaveError: null,
    userDeleteStatus: Status.INIT,
    userDeleteError: null
};

export default function administratorReducer(state = initialState, action: any) {
    switch (action.type) {
        case ActionTypes.ORGANIZATIONS_LOAD_COMPLETE: {
            return {
                ...state,
                organizationsLoadStatus: Status.COMPLETE,
                organizations: action.payload,
                organizationsLoadError: null
            }
        }
        case ActionTypes.ORGANIZATIONS_LOAD_ERROR: {
            return {
                ...state,
                organizationsLoadStatus: Status.ERROR,
                organizationsLoadError: action.payload
            }
        }
        case ActionTypes.ORGANIZATIONS_LOAD_PROCESSING: {
            return {
                ...state,
                organizationsLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.PERMISSIONS_LOAD_COMPLETE: {
            return {
                ...state,
                permissionsLoadStatus: Status.COMPLETE,
                permissions: action.payload,
                permissionsLoadError: null
            }
        }
        case ActionTypes.PERMISSIONS_LOAD_ERROR: {
            return {
                ...state,
                permissionsLoadStatus: Status.ERROR,
                permissionsLoadError: action.payload
            }
        }
        case ActionTypes.PERMISSIONS_LOAD_PROCESSING: {
            return {
                ...state,
                permissionsLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.PERMISSIONTYPES_LOAD_COMPLETE: {
            return {
                ...state,
                permissionTypesLoadStatus: Status.COMPLETE,
                permissionTypes: action.payload,
                permissionTypesLoadError: null
            }
        }
        case ActionTypes.PERMISSIONTYPES_LOAD_ERROR: {
            return {
                ...state,
                permissionTypesLoadStatus: Status.ERROR,
                permissionTypesLoadError: action.payload
            }
        }
        case ActionTypes.PERMISSIONTYPES_LOAD_PROCESSING: {
            return {
                ...state,
                permissionTypesLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.PERMISSION_SAVE_COMPLETE: {
            return {
                ...state,
                permissionSaveStatus: Status.COMPLETE,
                permissionSaveError: null
            }
        }
        case ActionTypes.PERMISSION_SAVE_ERROR: {
            return {
                ...state,
                permissionSaveStatus: Status.ERROR,
                permissionSaveError: action.payload
            }
        }
        case ActionTypes.PERMISSION_SAVE_PROCESSING: {
            return {
                ...state,
                permissionSaveStatus: Status.LOADING
            }
        }
        case ActionTypes.ROLES_LOAD_COMPLETE: {
            return {
                ...state,
                rolesLoadStatus: Status.COMPLETE,
                roles: action.payload,
                rolesLoadError: null
            }
        }
        case ActionTypes.ROLES_LOAD_ERROR: {
            return {
                ...state,
                rolesLoadStatus: Status.ERROR,
                rolesLoadError: action.payload
            }
        }
        case ActionTypes.ROLES_LOAD_PROCESSING: {
            return {
                ...state,
                rolesLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.USERS_LOAD_COMPLETE: {
            return {
                ...state,
                usersLoadStatus: Status.COMPLETE,
                users: action.payload,
                usersLoadError: null
            }
        }
        case ActionTypes.USERS_LOAD_ERROR: {
            return {
                ...state,
                usersLoadStatus: Status.ERROR,
                usersLoadError: action.payload
            }
        }
        case ActionTypes.USERS_LOAD_PROCESSING: {
            return {
                ...state,
                usersLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.USER_SAVE_COMPLETE: {
            return {
                ...state,
                userSaveStatus: Status.COMPLETE,
                userSaveError: null
            }
        }
        case ActionTypes.USER_SAVE_ERROR: {
            return {
                ...state,
                userSaveStatus: Status.ERROR,
                userSaveError: action.payload
            }
        }
        case ActionTypes.USER_SAVE_PROCESSING: {
            return {
                ...state,
                userSaveStatus: Status.LOADING,
            }
        }
        case ActionTypes.USER_DELETE_COMPLETE: {
            return {
                ...state,
                userDeleteStatus: Status.COMPLETE,
                userDeleteError: null
            }
        }
        case ActionTypes.USER_DELETE_ERROR: {
            return {
                ...state,
                userDeleteStatus: Status.ERROR,
                userDeleteError: action.payload
            }
        }
        case ActionTypes.USER_DELETE_PROCESSING: {
            return {
                ...state,
                userDeleteStatus: Status.LOADING,
            }
        }
        default:
            return state
    }
}