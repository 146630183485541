import Company from "@common/domain/entities/Company";
import Hub from "@common/domain/entities/Hub";
import HubType from "@common/domain/entities/HubType";
import News from "@common/domain/entities/News";
import Sector from "@common/domain/entities/Sector";
import Tag from "@common/domain/entities/Tag";
import User from "@common/domain/entities/User";
import RequestorInteractor from "@common/domain/interactors/RequestorInteractor";

export enum ActionTypes {
    HUBTYPE_SAVE_PROCESSING = '@requestor/HUBTYPE_SAVE_PROCESSING',
    HUBTYPE_SAVE_COMPLETE = '@requestor/HUBTYPE_SAVE_COMPLETE',
    HUBTYPE_SAVE_ERROR = '@requestor/HUBTYPE_SAVE_ERROR',
    HUBTYPES_LOAD_PROCESSING = '@requestor/HUBTYPES_LOAD_PROCESSING',
    HUBTYPES_LOAD_COMPLETE = '@requestor/HUBTYPES_LOAD_COMPLETE',
    HUBTYPES_LOAD_ERROR = '@requestor/HUBTYPES_LOAD_ERROR',
    HUBTYPE_DELETE_PROCESSING = '@requestor/HUBTYPE_DELETE_PROCESSING',
    HUBTYPE_DELETE_COMPLETE = '@requestor/HUBTYPE_DELETE_COMPLETE',
    HUBTYPE_DELETE_ERROR = '@requestor/HUBTYPE_DELETE_ERROR',
    SECTOR_SAVE_PROCESSING = '@requestor/SECTOR_SAVE_PROCESSING',
    SECTOR_SAVE_COMPLETE = '@requestor/SECTOR_SAVE_COMPLETE',
    SECTOR_SAVE_ERROR = '@requestor/SECTOR_SAVE_ERROR',
    SECTORS_LOAD_PROCESSING = '@requestor/SECTORS_LOAD_PROCESSING',
    SECTORS_LOAD_COMPLETE = '@requestor/SECTORS_LOAD_COMPLETE',
    SECTORS_LOAD_ERROR = '@requestor/SECTORS_LOAD_ERROR',
    SECTOR_DELETE_PROCESSING = '@requestor/SECTOR_DELETE_PROCESSING',
    SECTOR_DELETE_COMPLETE = '@requestor/SECTOR_DELETE_COMPLETE',
    SECTOR_DELETE_ERROR = '@requestor/SECTOR_DELETE_ERROR',
    TAG_SAVE_PROCESSING = '@requestor/TAG_SAVE_PROCESSING',
    TAG_SAVE_COMPLETE = '@requestor/TAG_SAVE_COMPLETE',
    TAG_SAVE_ERROR = '@requestor/TAG_SAVE_ERROR',
    TAGS_LOAD_PROCESSING = '@requestor/TAGS_LOAD_PROCESSING',
    TAGS_LOAD_COMPLETE = '@requestor/TAGS_LOAD_COMPLETE',
    TAGS_LOAD_ERROR = '@requestor/TAGS_LOAD_ERROR',
    TAG_DELETE_PROCESSING = '@requestor/TAG_DELETE_PROCESSING',
    TAG_DELETE_COMPLETE = '@requestor/TAG_DELETE_COMPLETE',
    TAG_DELETE_ERROR = '@requestor/TAG_DELETE_ERROR',
    HUB_SAVE_PROCESSING = '@requestor/HUB_SAVE_PROCESSING',
    HUB_SAVE_COMPLETE = '@requestor/HUB_SAVE_COMPLETE',
    HUB_SAVE_ERROR = '@requestor/HUB_SAVE_ERROR',
    HUBS_LOAD_PROCESSING = '@requestor/HUBS_LOAD_PROCESSING',
    HUBS_LOAD_COMPLETE = '@requestor/HUBS_LOAD_COMPLETE',
    HUBS_LOAD_ERROR = '@requestor/HUBS_LOAD_ERROR',
    HUB_DELETE_PROCESSING = '@requestor/HUB_DELETE_PROCESSING',
    HUB_DELETE_COMPLETE = '@requestor/HUB_DELETE_COMPLETE',
    HUB_DELETE_ERROR = '@requestor/HUB_DELETE_ERROR',
    HUB_LOAD_PROCESSING = '@requestor/HUB_LOAD_PROCESSING',
    HUB_LOAD_COMPLETE = '@requestor/HUB_LOAD_COMPLETE',
    HUB_LOAD_ERROR = '@requestor/HUB_LOAD_ERROR',
    COMPANY_SAVE_PROCESSING = '@requestor/COMPANY_SAVE_PROCESSING',
    COMPANY_SAVE_COMPLETE = '@requestor/COMPANY_SAVE_COMPLETE',
    COMPANY_SAVE_ERROR = '@requestor/COMPANY_SAVE_ERROR',
    COMPANIES_LOAD_PROCESSING = '@requestor/COMPANIES_LOAD_PROCESSING',
    COMPANIES_LOAD_COMPLETE = '@requestor/COMPANIES_LOAD_COMPLETE',
    COMPANIES_LOAD_ERROR = '@requestor/COMPANIES_LOAD_ERROR',
    COMPANIESSEARCH_LOAD_PROCESSING = '@requestor/COMPANIESSEARCH_LOAD_PROCESSING',
    COMPANIESSEARCH_LOAD_COMPLETE = '@requestor/COMPANIESSEARCH_LOAD_COMPLETE',
    COMPANIESSEARCH_LOAD_ERROR = '@requestor/COMPANIESSEARCH_LOAD_ERROR',
    COMPANY_LOAD_PROCESSING = '@requestor/COMPANY_LOAD_PROCESSING',
    COMPANY_LOAD_COMPLETE = '@requestor/COMPANY_LOAD_COMPLETE',
    COMPANY_LOAD_ERROR = '@requestor/COMPANY_LOAD_ERROR',
    COMPANY_DELETE_PROCESSING = '@requestor/COMPANY_DELETE_PROCESSING',
    COMPANY_DELETE_COMPLETE = '@requestor/COMPANY_DELETE_COMPLETE',
    COMPANY_DELETE_ERROR = '@requestor/COMPANY_DELETE_ERROR',
    NEWS_SAVE_PROCESSING = '@requestor/NEWS_SAVE_PROCESSING',
    NEWS_SAVE_COMPLETE = '@requestor/NEWS_SAVE_COMPLETE',
    NEWS_SAVE_ERROR = '@requestor/NEWS_SAVE_ERROR',
    NEWS_LOAD_PROCESSING = '@requestor/NEWS_LOAD_PROCESSING',
    NEWS_LOAD_COMPLETE = '@requestor/NEWS_LOAD_COMPLETE',
    NEWS_LOAD_ERROR = '@requestor/NEWS_LOAD_ERROR',
    NEWS_DELETE_PROCESSING = '@requestor/NEWS_DELETE_PROCESSING',
    NEWS_DELETE_COMPLETE = '@requestor/NEWS_DELETE_COMPLETE',
    NEWS_DELETE_ERROR = '@requestor/NEWS_DELETE_ERROR'
};

export const saveHubType = (user: User, hubType: HubType) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.HUBTYPE_SAVE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.HUBTYPE_SAVE_COMPLETE, payload: await deps.requestorInteractor.saveHubType(user, hubType)});
        }catch(error){
            dispatch({ type: ActionTypes.HUBTYPE_SAVE_ERROR, payload: error});
        }
    };
}

export const loadHubTypes = (user: User) => {
    console.log('Loading: ', user)
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.HUBTYPES_LOAD_PROCESSING});
        try{
            console.log('Loading1: ', deps)
            dispatch({ type: ActionTypes.HUBTYPES_LOAD_COMPLETE, payload: await deps.requestorInteractor.getHubTypes(user)});
        }catch(error){
            console.log('Loading:2 ', error)
            dispatch({ type: ActionTypes.HUBTYPES_LOAD_ERROR, payload: error});
        }
    };
}
export const deleteHubType = (user: User, hubType: HubType) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.HUBTYPE_DELETE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.HUBTYPE_DELETE_COMPLETE, payload: await deps.requestorInteractor.deleteHubType(user, hubType)});
        }catch(error){
            dispatch({ type: ActionTypes.HUBTYPE_DELETE_ERROR, payload: error});
        }
    };
}

export const saveSector = (user: User, sector: Sector) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.SECTOR_SAVE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.SECTOR_SAVE_COMPLETE, payload: await deps.requestorInteractor.saveSector(user, sector)});
        }catch(error){
            dispatch({ type: ActionTypes.SECTOR_SAVE_ERROR, payload: error});
        }
    };
}

export const loadSectors = (user: User) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.SECTORS_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.SECTORS_LOAD_COMPLETE, payload: await deps.requestorInteractor.getSectors(user)});
        }catch(error){
            dispatch({ type: ActionTypes.SECTORS_LOAD_ERROR, payload: error});
        }
    };
}
export const deleteSector = (user: User, sector: Sector) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.SECTOR_DELETE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.SECTOR_DELETE_COMPLETE, payload: await deps.requestorInteractor.deleteSector(user, sector)});
        }catch(error){
            dispatch({ type: ActionTypes.SECTOR_DELETE_ERROR, payload: error});
        }
    };
}

export const saveTag = (user: User, tag: Tag) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.TAG_SAVE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.TAG_SAVE_COMPLETE, payload: await deps.requestorInteractor.saveTag(user, tag)});
        }catch(error){
            dispatch({ type: ActionTypes.TAG_SAVE_ERROR, payload: error});
        }
    };
}

export const loadTags = (user: User) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.TAGS_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.TAGS_LOAD_COMPLETE, payload: await deps.requestorInteractor.getTags(user)});
        }catch(error){
            dispatch({ type: ActionTypes.TAGS_LOAD_ERROR, payload: error});
        }
    };
}
export const deleteTag = (user: User, tag: Tag) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.TAG_DELETE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.TAG_DELETE_COMPLETE, payload: await deps.requestorInteractor.deleteTag(user, tag)});
        }catch(error){
            dispatch({ type: ActionTypes.TAG_DELETE_ERROR, payload: error});
        }
    };
}

export const saveHub = (user: User, hub: Hub) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.HUB_SAVE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.HUB_SAVE_COMPLETE, payload: await deps.requestorInteractor.saveHub(user, hub)});
        }catch(error){
            dispatch({ type: ActionTypes.HUB_SAVE_ERROR, payload: error});
        }
    };
}

export const loadHubs = (user: User, name: string, hubType?: HubType, sector?: Sector) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.HUBS_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.HUBS_LOAD_COMPLETE, payload: await deps.requestorInteractor.getHubs(user,name,hubType,sector) });
        }catch(error){
            dispatch({ type: ActionTypes.HUBS_LOAD_ERROR, payload: error});
        }
    };
}
export const deleteHub = (user: User, hub: Hub) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.HUB_DELETE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.HUB_DELETE_COMPLETE, payload: await deps.requestorInteractor.deleteHub(user, hub)});
        }catch(error){
            dispatch({ type: ActionTypes.HUB_DELETE_ERROR, payload: error});
        }
    };
}
export const loadHub = (hubId :string) => {
    console.log(hubId);
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.HUB_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.HUB_LOAD_COMPLETE, payload: await deps.requestorInteractor.getHubById(hubId) });
        }catch(error){
            dispatch({ type: ActionTypes.HUB_LOAD_ERROR, payload: error});
        }
    };
}

export const saveCompany = (user: User, company: Company) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.COMPANY_SAVE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.COMPANY_SAVE_COMPLETE, payload: await deps.requestorInteractor.saveCompany(user, company)});
        }catch(error){
            dispatch({ type: ActionTypes.COMPANY_SAVE_ERROR, payload: error});
        }
    };
}

export const loadCompanies = (user: User, name?: string, sector?: Sector, isstartup?: boolean, isinvestor?: boolean,state?:string) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.COMPANIES_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.COMPANIES_LOAD_COMPLETE, payload: await deps.requestorInteractor.getCompanies(user,name, sector,isstartup,isinvestor,state) });
        }catch(error){
            dispatch({ type: ActionTypes.COMPANIES_LOAD_ERROR, payload: error});
        }
    };
}

export const loadCompaniesSearch = (user: User, name: string) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.COMPANIESSEARCH_LOAD_PROCESSING});
        try{
            console.log(name);
            dispatch({ type: ActionTypes.COMPANIESSEARCH_LOAD_COMPLETE, payload: await deps.requestorInteractor.getCompanies(user,name)});
        }catch(error){
            dispatch({ type: ActionTypes.COMPANIESSEARCH_LOAD_ERROR, payload: error});
        }
    };
}

export const loadCompany = (companyId:string) => {
    console.log(companyId);
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.COMPANY_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.COMPANY_LOAD_COMPLETE, payload: await deps.requestorInteractor.getCompanyById(companyId) });
        }catch(error){
            dispatch({ type: ActionTypes.COMPANY_LOAD_ERROR, payload: error});
        }
    };
}

export const deleteCompany = (user: User, company: Company) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.COMPANY_DELETE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.COMPANY_DELETE_COMPLETE, payload: await deps.requestorInteractor.deleteCompany(user, company)});
        }catch(error){
            dispatch({ type: ActionTypes.COMPANY_DELETE_ERROR, payload: error});
        }
    };
}

export const saveNews = (user: User, news: News) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.NEWS_SAVE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.NEWS_SAVE_COMPLETE, payload: await deps.requestorInteractor.saveNews(user, news)});
        }catch(error){
            dispatch({ type: ActionTypes.NEWS_SAVE_ERROR, payload: error});
        }
    };
}

export const loadNews = () => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.NEWS_LOAD_PROCESSING});
        try{
            dispatch({ type: ActionTypes.NEWS_LOAD_COMPLETE, payload: await deps.requestorInteractor.getNews()});
        }catch(error){
            dispatch({ type: ActionTypes.NEWS_LOAD_ERROR, payload: error});
        }
    };
}
export const deleteNews = (user: User, news: News) => {
    return async (dispatch: any, getState: any, deps: { requestorInteractor: RequestorInteractor }) => {
        dispatch({ type: ActionTypes.NEWS_DELETE_PROCESSING});
        try{
            dispatch({ type: ActionTypes.NEWS_DELETE_COMPLETE, payload: await deps.requestorInteractor.deleteNews(user, news)});
        }catch(error){
            dispatch({ type: ActionTypes.NEWS_DELETE_ERROR, payload: error});
        }
    };
}