import React from 'react'
import {  useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Container, Stack } from '@mui/material';
import logo from '../../../../assets/images/header/logo.png';
import { Link } from 'react-router-dom';
import SearchInput, { Icon } from "../../../pages/front/widgets/SearchInput";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useAuth } from '../../../../hooks/useAuth';
import { loadCompaniesSearch } from '../../../../redux/actions/requestor';
import { defaultInvestorImage, defaultStartupImage } from '../../../pages/front/widgets/DefaultImages';

export default function Header() {
    const {logout, user} = (useAuth());
    const linkCSS = {
        fontWeight: 'bold',
        color: '#364152',
        textDecoration: 'none'
    };
    
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.session.loggedUser);
    const companiessearch = useSelector((state) => state.requestor.companiessearch);
    const searchCompanies =(company) => {
        dispatch(loadCompaniesSearch(loggedUser,company));
        console.log("companies from header: ", companiessearch);
    }
    return (
        <Box
            sx={{
                minHeight: '40px',
                marginTop: '2%'
            }}
        >
            <Container>
                <Stack direction={'row'} justifyItems='center' alignContent='center' sx={{position:'relative'}} alignItems={'center'} spacing={3}>
                    <Link to={"/"}><img src={logo} width={284}/></Link>
                    <Link to={"/aboutus"} style={linkCSS}>Nosotros</Link>
                    <Link to={"/investors"} style={linkCSS}>Inversores</Link>
                    <Link to={"/startups"} style={linkCSS}>Startups</Link>
                    <Link to={"/incubators"} style={{...linkCSS, whiteSpace: 'nowrap'}}>Entidades de Soporte</Link>
                    <Link to={"/contactus"} style={linkCSS}>Contacto</Link>
                    <SearchInput style={{marginLeft: '100px', height: '50px', width: '190px'}} color='white' icon={Icon.RIGHT} backgroundColor="#01cbbb" onSearch={value => {
                        searchCompanies(value);
                    }} />
                    {companiessearch?.length>0 && (
                        <Box sx={{minWidth:'200px',minHeight:'50px', position:'absolute',background:'white', top:'4.5rem', right:'4rem', borderRadius:'36px',border:'solid 1px rgb(1, 203, 187)', padding:'0.7rem'}}>
                            {companiessearch.map(companies=>(
                                <Link onClick={()=>{searchCompanies('')}} key={companies.id} to={"/companyDetail/"+companies.id} style={{paddingBottom:'0.5rem', paddingLeft:'1rem', textDecoration:'none', color:'black', fontWeight:'bold', display:'flex', alignItems:'center'}}><Avatar alt={companies.name} src={companies.profilepic || (companies.isinvestor ? defaultInvestorImage : defaultStartupImage)} sx={{marginRight:'0.5rem'}} /> {companies.name}</Link>
                            ))}
                        </Box>
                    )}
                    {!user && (<Link to={"/login"}><LockIcon /></Link>)}
                    {user && (<Link to={"#"} onClick={()=>logout()} ><LockOpenIcon /></Link>)}
                </Stack>
            </Container>
        </Box>
    )
};