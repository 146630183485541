import TagRepository from "@common/domain/data/TagRepository";
import Tag from "@common/domain/entities/Tag";
import User from "@common/domain/entities/User";
import { v4 as uuidv4 } from 'uuid';
import { tags } from "./InitData";

export default class MockTagRepository implements TagRepository{

    private static tags: Tag[] = tags;

    async saveTag(user: User, tag: Tag): Promise<Tag> {
        if (!tag.id){
            tag.id = uuidv4();
            MockTagRepository.tags.push(tag);
            return tag;
        }else{
            const record = MockTagRepository.tags.find(record => record.id === tag.id);
            if (record){
                record.name = tag.name;
            }
            return record as Tag;
        }
    }
    async getTags(user: User): Promise<Tag[]> {
        return MockTagRepository.tags;
    }
    async deleteTag(user: User, tag: Tag): Promise<boolean> {
        const record = MockTagRepository.tags.find(record => record.id === tag.id);
        if (record){
            MockTagRepository.tags = MockTagRepository.tags.filter(record => record.id !== tag.id)
            return true;
        }
        return false;
    }

}