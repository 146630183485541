import PermissionRepository from "@common/domain/data/PermissionRepository";
import Permission from "@common/domain/entities/Permission";
import User from "@common/domain/entities/User";
import { v4 as uuidv4 } from 'uuid';
import { permissions } from "./InitData";

export default class MockPermissionRepository implements PermissionRepository{

    private static permissions: Permission[] = permissions;

    async savePermission(user: User, permission: Permission): Promise<Permission> {
        if (!permission.id){
            permission.id = uuidv4();
            MockPermissionRepository.permissions.push(permission);
            return permission;
        }else{
            const record = MockPermissionRepository.permissions.find(record => record.id === permission.id);
            if (record){
                record.permissionType = permission.permissionType;
                record.role = permission.role;
                record.value = permission.value;
            }
            return record as Permission;
        }
    }
}