// assets
import { PeopleAlt } from '@mui/icons-material';
import { RoleLevel } from '@common/domain/entities/Role';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const administrator = {
  id: 'administrator',
  title: 'Administración',
  caption: 'Usuarios & Permisos',
  type: 'group',
  level: RoleLevel.Administrator,
  children: [{
    id: 'users',
    title: 'Usuarios',
    type: 'item',
    icon: PeopleAlt,
    url: '/users',
    breadcrumbs: false
  }]
};

export default administrator;