import React from 'react'
import { Box, Container, Stack } from '@mui/material';
import logo from '../../../../assets/images/footer/logo_gray.png';
import linkedIn from '../../../../assets/images/footer/linkedin.png';
import facebook from '../../../../assets/images/footer/facebook.png';
import instagram from '../../../../assets/images/footer/instagram.png';
import youtube from '../../../../assets/images/footer/youtube.png';
import organizations from '../../../../assets/images/footer/organizations.png';

export default function Footer(props: any) {
    const linkCSS = {
        fontWeight: 'bold',
        color: 'white'
    };
    const openFacebook = () => {
        window.open("https://www.facebook.com/CREEASLL?mibextid=LQQJ4d", "_blank");
    }
    const openYoutube = () => {
        window.open("https://youtube.com/@creeaslalibertad3945?si=msAgyYaRi_8FKYQk", "_blank");
    }
    return (
        <Stack direction={'column'} alignItems={'center'}>
            <Box
                sx={{
                    width: '100%',
                    minHeight: '150px',
                    backgroundColor: '#d6d6d6',
                    paddingTop: '40px'
                }}
            >
                <Container>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={3} style={{paddingInlineStart: '100px', paddingInlineEnd: '100px'}}>
                        <img src={logo} width={284} />
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <img src={linkedIn} height={'60px'} />
                            <img onClick={()=>{openFacebook()}} src={facebook} height={'60px'} style={{cursor:'pointer'}} />
                            <img src={instagram} height={'60px'} />
                            <img onClick={()=>{openYoutube()}} src={youtube} height={'60px'} style={{cursor:'pointer'}} />
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    minHeight: '120px',
                    backgroundColor: '#d6d6d6',
                }}
            >
                <Container>
                    <Stack direction={'column'} alignItems={'center'} spacing={1}>
                        <h4>También puedes visitar</h4>
                        <img src={organizations} height={'65px'} />
                    </Stack>
                </Container>
            </Box>
            <Box
                sx={{
                    minHeight: '40px',
                    backgroundColor: '#32a936',
                    width: '100%'
                }}
            >
                <Container style={{minHeight: '40px'}}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} style={{minHeight: '40px', paddingInlineStart: '100px', paddingInlineEnd: '100px'}} spacing={3}>
                        <Box sx={linkCSS}>&copy; 2023 "La Libertad Innova". Todos los derechos reservados</Box>
                        <Box sx={linkCSS}>CREEAS La Libertad</Box>
                    </Stack>
                </Container>
            </Box>
        </Stack>
    )
};