export default class Tag{
    private _id: string | undefined;
    private _name: string;

    constructor(id: string | undefined, name: string) {
        this._id = id;
        this._name = name;
    }
    public get id(): string | undefined {
        return this._id;
    }
    public set id(value: string | undefined) {
        this._id = value;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
}