import { ActionTypes } from "../actions/requestor";
import HubType from "@common/domain/entities/HubType";
import Sector from "@common/domain/entities/Sector";
import Hub from "@common/domain/entities/Hub";
import Company from "@common/domain/entities/Company";
import News from "@common/domain/entities/News";

export enum Status {
    INIT,
    LOADING,
    COMPLETE,
    ERROR,
};

export type RequestorState = {
    hubTypeSaveStatus: Status;
    hubTypeSaveError: any;
    hubTypeLoadStatus: Status;
    hubType: HubType[];
    hubTypeDeleteStatus: Status;
    hubTypeDeleteError: any;
    hubTypeLoadError: any;
    sectorSaveStatus: Status;
    sectorSaveError: any;
    sectorLoadStatus: Status;
    sector: Sector[];
    sectorDeleteStatus: Status;
    sectorDeleteError: any;
    sectorLoadError: any;
    hubSaveStatus: Status;
    hubSaveError: any;
    hubLoadStatus: Status;
    hub: Hub[];
    hubDeleteStatus: Status;
    hubDeleteError: any;
    hubLoadError: any;
    companySaveStatus: Status;
    companySaveError: any;
    companyLoadStatus: Status;
    company: Company[];
    companiessearch: Company[];
    companyDeleteStatus: Status;
    companyDeleteError: any;
    companyLoadError: any;
    newsSaveStatus: Status;
    newsSaveError: any;
    newsLoadStatus: Status;
    news: News[];
    newsDeleteStatus: Status;
    newsDeleteError: any;
    newsLoadError: any;
};

const requestorState: RequestorState = {
    hubTypeSaveStatus: Status.INIT,
    hubTypeSaveError: null,
    hubTypeLoadStatus: Status.INIT,
    hubType: [] as HubType[],
    hubTypeDeleteStatus: Status.INIT,
    hubTypeDeleteError: null,
    hubTypeLoadError: null,
    sectorSaveStatus: Status.INIT,
    sectorSaveError: null,
    sectorLoadStatus: Status.INIT,
    sector: [] as Sector[],
    sectorDeleteStatus: Status.INIT,
    sectorDeleteError: null,
    sectorLoadError: null,
    hubSaveStatus: Status.INIT,
    hubSaveError: null,
    hubLoadStatus: Status.INIT,
    hub: [] as Hub[],
    hubDeleteStatus: Status.INIT,
    hubDeleteError: null,
    hubLoadError: null,
    companySaveStatus: Status.INIT,
    companySaveError: null,
    companyLoadStatus: Status.INIT,
    company: [] as Company[],
    companiessearch: [] as Company[],
    companyDeleteStatus: Status.INIT,
    companyDeleteError: null,
    companyLoadError: null,
    newsSaveStatus: Status.INIT,
    newsSaveError: null,
    newsLoadStatus: Status.INIT,
    news: [] as News[],
    newsDeleteStatus: Status.INIT,
    newsDeleteError: null,
    newsLoadError: null,
};

export default function requestorReducer(state = requestorState, action: any) {
    switch (action.type) {
        case ActionTypes.HUBTYPES_LOAD_COMPLETE: {
            return {
                ...state,
                hubTypesLoadStatus: Status.COMPLETE,
                hubTypes: action.payload,
                hubTypesLoadError: null
            }
        }
        case ActionTypes.HUBTYPES_LOAD_ERROR: {
            return {
                ...state,
                hubTypesLoadStatus: Status.ERROR,
                hubTypesLoadError: action.payload
            }
        }
        case ActionTypes.HUBTYPES_LOAD_PROCESSING: {
            return {
                ...state,
                hubTypesLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.HUBTYPE_SAVE_COMPLETE: {
            return {
                ...state,
                hubTypeSaveStatus: Status.COMPLETE,
                hubTypeSaveError: null
            }
        }
        case ActionTypes.HUBTYPE_SAVE_ERROR: {
            return {
                ...state,
                hubTypeSaveStatus: Status.ERROR,
                hubTypeSaveError: action.payload
            }
        }
        case ActionTypes.HUBTYPE_SAVE_PROCESSING: {
            return {
                ...state,
                hubTypeSaveStatus: Status.LOADING
            }
        }
        case ActionTypes.HUBTYPE_DELETE_COMPLETE: {
            return {
                ...state,
                hubTypeDeleteStatus: Status.COMPLETE,
                hubTypeDeleteError: null
            }
        }
        case ActionTypes.HUBTYPE_DELETE_ERROR: {
            return {
                ...state,
                hubTypeDeleteStatus: Status.ERROR,
                hubTypeDeleteError: action.payload
            }
        }
        case ActionTypes.HUBTYPE_DELETE_PROCESSING: {
            return {
                ...state,
                hubTypeDeleteStatus: Status.LOADING
            }
        }
        case ActionTypes.SECTORS_LOAD_COMPLETE: {
            return {
                ...state,
                sectorsLoadStatus: Status.COMPLETE,
                sectors: action.payload,
                sectorsLoadError: null
            }
        }
        case ActionTypes.SECTORS_LOAD_ERROR: {
            return {
                ...state,
                sectorsLoadStatus: Status.ERROR,
                sectorsLoadError: action.payload
            }
        }
        case ActionTypes.SECTORS_LOAD_PROCESSING: {
            return {
                ...state,
                sectorsLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.SECTOR_SAVE_COMPLETE: {
            return {
                ...state,
                sectorSaveStatus: Status.COMPLETE,
                sectorSaveError: null
            }
        }
        case ActionTypes.SECTOR_SAVE_ERROR: {
            return {
                ...state,
                sectorSaveStatus: Status.ERROR,
                sectorSaveError: action.payload
            }
        }
        case ActionTypes.SECTOR_SAVE_PROCESSING: {
            return {
                ...state,
                sectorSaveStatus: Status.LOADING
            }
        }
        case ActionTypes.SECTOR_DELETE_COMPLETE: {
            return {
                ...state,
                sectorDeleteStatus: Status.COMPLETE,
                sectorDeleteError: null
            }
        }
        case ActionTypes.SECTOR_DELETE_ERROR: {
            return {
                ...state,
                sectorDeleteStatus: Status.ERROR,
                sectorDeleteError: action.payload
            }
        }
        case ActionTypes.SECTOR_DELETE_PROCESSING: {
            return {
                ...state,
                sectorDeleteStatus: Status.LOADING
            }
        }
        case ActionTypes.TAGS_LOAD_COMPLETE: {
            return {
                ...state,
                tagsLoadStatus: Status.COMPLETE,
                tags: action.payload,
                tagsLoadError: null
            }
        }
        case ActionTypes.TAGS_LOAD_ERROR: {
            return {
                ...state,
                tagsLoadStatus: Status.ERROR,
                tagsLoadError: action.payload
            }
        }
        case ActionTypes.TAGS_LOAD_PROCESSING: {
            return {
                ...state,
                tagsLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.TAG_SAVE_COMPLETE: {
            return {
                ...state,
                tagSaveStatus: Status.COMPLETE,
                tagSaveError: null
            }
        }
        case ActionTypes.TAG_SAVE_ERROR: {
            return {
                ...state,
                tagSaveStatus: Status.ERROR,
                tagSaveError: action.payload
            }
        }
        case ActionTypes.TAG_SAVE_PROCESSING: {
            return {
                ...state,
                tagSaveStatus: Status.LOADING
            }
        }
        case ActionTypes.TAG_DELETE_COMPLETE: {
            return {
                ...state,
                tagDeleteStatus: Status.COMPLETE,
                tagDeleteError: null
            }
        }
        case ActionTypes.TAG_DELETE_ERROR: {
            return {
                ...state,
                tagDeleteStatus: Status.ERROR,
                tagDeleteError: action.payload
            }
        }
        case ActionTypes.TAG_DELETE_PROCESSING: {
            return {
                ...state,
                tagDeleteStatus: Status.LOADING
            }
        }
        case ActionTypes.HUBS_LOAD_COMPLETE: {
            return {
                ...state,
                hubsLoadStatus: Status.COMPLETE,
                hubs: action.payload,
                hubsLoadError: null
            }
        }
        case ActionTypes.HUBS_LOAD_ERROR: {
            return {
                ...state,
                hubsLoadStatus: Status.ERROR,
                hubsLoadError: action.payload
            }
        }
        case ActionTypes.HUBS_LOAD_PROCESSING: {
            return {
                ...state,
                hubsLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.HUB_LOAD_COMPLETE: {
            return {
                ...state,
                hubLoadStatus: Status.COMPLETE,
                hub: action.payload,
                hubLoadError: null
            }
        }
        case ActionTypes.HUB_LOAD_ERROR: {
            return {
                ...state,
                hubLoadStatus: Status.ERROR,
                hubLoadError: action.payload
            }
        }
        case ActionTypes.HUB_LOAD_PROCESSING: {
            return {
                ...state,
                hubLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.HUB_SAVE_COMPLETE: {
            return {
                ...state,
                hubSaveStatus: Status.COMPLETE,
                hubSaveError: null
            }
        }
        case ActionTypes.HUB_SAVE_ERROR: {
            return {
                ...state,
                hubSaveStatus: Status.ERROR,
                hubSaveError: action.payload
            }
        }
        case ActionTypes.HUB_SAVE_PROCESSING: {
            return {
                ...state,
                hubSaveStatus: Status.LOADING
            }
        }
        case ActionTypes.HUB_DELETE_COMPLETE: {
            return {
                ...state,
                hubDeleteStatus: Status.COMPLETE,
                hubDeleteError: null
            }
        }
        case ActionTypes.HUB_DELETE_ERROR: {
            return {
                ...state,
                hubDeleteStatus: Status.ERROR,
                hubDeleteError: action.payload
            }
        }
        case ActionTypes.HUB_DELETE_PROCESSING: {
            return {
                ...state,
                hubDeleteStatus: Status.LOADING
            }
        }
        case ActionTypes.COMPANIES_LOAD_COMPLETE: {
            return {
                ...state,
                companiesLoadStatus: Status.COMPLETE,
                companies: action.payload,
                companiesLoadError: null
            }
        }
        case ActionTypes.COMPANIES_LOAD_ERROR: {
            return {
                ...state,
                companiesLoadStatus: Status.ERROR,
                companiesLoadError: action.payload
            }
        }
        case ActionTypes.COMPANIES_LOAD_PROCESSING: {
            return {
                ...state,
                companiesLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.COMPANIESSEARCH_LOAD_COMPLETE: {
            return {
                ...state,
                companiessearchLoadStatus: Status.COMPLETE,
                companiessearch: action.payload,
                companiessearchLoadError: null
            }
        }
        case ActionTypes.COMPANIESSEARCH_LOAD_ERROR: {
            return {
                ...state,
                companiessearchLoadStatus: Status.ERROR,
                companiessearchLoadError: action.payload
            }
        }
        case ActionTypes.COMPANIESSEARCH_LOAD_PROCESSING: {
            return {
                ...state,
                companiessearchLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.COMPANY_LOAD_COMPLETE: {
            return {
                ...state,
                companyLoadStatus: Status.COMPLETE,
                company: action.payload,
                companyLoadError: null
            }
        }
        case ActionTypes.COMPANY_LOAD_ERROR: {
            return {
                ...state,
                companyLoadStatus: Status.ERROR,
                companyLoadError: action.payload
            }
        }
        case ActionTypes.COMPANY_LOAD_PROCESSING: {
            return {
                ...state,
                companyLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.COMPANY_SAVE_COMPLETE: {
            return {
                ...state,
                companySaveStatus: Status.COMPLETE,
                companySaveError: null
            }
        }
        case ActionTypes.COMPANY_SAVE_ERROR: {
            return {
                ...state,
                companySaveStatus: Status.ERROR,
                companySaveError: action.payload
            }
        }
        case ActionTypes.COMPANY_SAVE_PROCESSING: {
            return {
                ...state,
                companySaveStatus: Status.LOADING
            }
        }
        case ActionTypes.COMPANY_DELETE_COMPLETE: {
            return {
                ...state,
                companyDeleteStatus: Status.COMPLETE,
                companyDeleteError: null
            }
        }
        case ActionTypes.COMPANY_DELETE_ERROR: {
            return {
                ...state,
                companyDeleteStatus: Status.ERROR,
                companyDeleteError: action.payload
            }
        }
        case ActionTypes.COMPANY_DELETE_PROCESSING: {
            return {
                ...state,
                companyDeleteStatus: Status.LOADING
            }
        }
        case ActionTypes.NEWS_LOAD_COMPLETE: {
            return {
                ...state,
                newsLoadStatus: Status.COMPLETE,
                news: action.payload,
                newsLoadError: null
            }
        }
        case ActionTypes.NEWS_LOAD_ERROR: {
            return {
                ...state,
                newsLoadStatus: Status.ERROR,
                newsLoadError: action.payload
            }
        }
        case ActionTypes.NEWS_LOAD_PROCESSING: {
            return {
                ...state,
                newsLoadStatus: Status.LOADING
            }
        }
        case ActionTypes.NEWS_SAVE_COMPLETE: {
            return {
                ...state,
                newsSaveStatus: Status.COMPLETE,
                newsSaveError: null
            }
        }
        case ActionTypes.NEWS_SAVE_ERROR: {
            return {
                ...state,
                newsSaveStatus: Status.ERROR,
                newsSaveError: action.payload
            }
        }
        case ActionTypes.NEWS_SAVE_PROCESSING: {
            return {
                ...state,
                newsSaveStatus: Status.LOADING
            }
        }
        case ActionTypes.NEWS_DELETE_COMPLETE: {
            return {
                ...state,
                newsDeleteStatus: Status.COMPLETE,
                newsDeleteError: null
            }
        }
        case ActionTypes.NEWS_DELETE_ERROR: {
            return {
                ...state,
                newsDeleteStatus: Status.ERROR,
                newsDeleteError: action.payload
            }
        }
        case ActionTypes.NEWS_DELETE_PROCESSING: {
            return {
                ...state,
                newsDeleteStatus: Status.LOADING
            }
        }
        default:
            return state
    }
}