import React from 'react'
import { lazy } from 'react';

// project imports
import Loadable from '../theme/widgets/Loadable';
import FrontLayout from '../theme/layout/FrontLayout';
import CompanyDetail from '../pages/front/CompanyDetail';

// routing
const Home = Loadable(lazy(() => import('../pages/front/Home')));
const Startups = Loadable(lazy(() => import('../pages/front/Startups')));
const Incubators = Loadable(lazy(() => import('../pages/front/Incubators')));
const Investors = Loadable(lazy(() => import('../pages/front/Investors')));
const AboutUs = Loadable(lazy(() => import('../pages/front/AboutUs')));
const ContactUs = Loadable(lazy(() => import('../pages/front/ContactUs')));
const IncubatorDetail = Loadable(lazy(() => import('../pages/front/IncubatorDetail'))); 

// ==============================|| MAIN ROUTING ||============================== //

const FrontRoutes = {
  path: '/',
  element: <FrontLayout />,
  children: [
    { path: '', element: <Home /> },
    { path: 'home', element: <Home /> },
    { path: 'startups', element: <Startups /> },
    { path: 'investors', element: <Investors /> },
    { path: 'incubators', element: <Incubators /> },
    { path: 'aboutus', element: <AboutUs /> },
    { path: 'contactus', element: <ContactUs /> },
    { path: 'companyDetail/:id', element: <CompanyDetail /> },
    { path: 'incubatorDetail/:id', element: <IncubatorDetail /> }
  ]
};

export default FrontRoutes;