import React from 'react'
import { lazy } from 'react';

// project imports
import MinimalLayout from '../theme/layout/MinimalLayout';
import Loadable from '../theme/widgets/Loadable';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('../pages/session/authentication/Login')));
//const AuthRegister = Loadable(lazy(() => import('../pages/session/authentication/Register')));
//const Landing = Loadable(lazy(() => import('../pages/front/Landing')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
//    { path: '', element: <Landing /> },
    { path: 'login', element: <AuthLogin /> },
    //{ path: '/register', element: <AuthRegister /> }
  ]
};

export default AuthenticationRoutes;
