import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { useNavigate, useParams } from "react-router-dom";
import { loadCompanies, loadCompany } from "../../../redux/actions/requestor";
import { Avatar, Box, Card, Chip, Container, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from "@mui/material";
import { ContactPageOutlined, Facebook, LocationOn, PersonOutlineOutlined, StarOutlined, TroubleshootOutlined, WhatsApp, YouTube } from "@mui/icons-material";
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import RoundedTable from "./widgets/RoundedTable";
import MapPicker from "react-google-map-picker";
import { defaultInvestorImage, defaultStartupImage } from "./widgets/DefaultImages";


export default function CompanyDetail() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const company = useSelector((state) => state.requestor.company);
    const companies = useSelector((state) => state.requestor.companies);
    const loggedUser = useSelector((state) => state.session.loggedUser);
    const [defaultLocation, setDefaultLocation] = useState('');
    const trStyle = {
        height: '35px'
    };
    const thStyle = {
        borderRightColor: 'white',
        paddingLeft: '30px',
        paddingRight: '30px',
    };
    const tdStyle = {
        paddingLeft: '20px',
        paddingRight: '20px',
        textAlign: 'center'
    };
    const {id} = useParams();
    useEffect(()=>{
        dispatch(loadCompany(id));
        console.log(company);
        loadLocation();
    }, []);
    useEffect(()=>{
        dispatch(loadCompany(id));
        dispatch(loadCompanies(loggedUser,undefined,undefined,company.isstartup,company.isinvestor));
        console.log("when company changes: ",companies);
        loadLocation();
    }, [id]);

    const loadLocation = () =>{
        if (company?.locations?.length > 0){
            const location = company.locations[0];
            console.log("location",location);
            setLocation(location.latitude,location.longitude,location.zoom);
        }
    }
    const setLocation = useCallback((ltd,lng,zoom) =>{
        console.log(ltd,lng,zoom);
        if (ltd && lng && zoom){
            setDefaultLocation("https://maps.googleapis.com/maps/api/staticmap?center="+ltd+","+lng+"&zoom="+zoom+"&size=300x300&markers=color:red%7Clabel:S%7C"+ltd+","+lng+"&key=AIzaSyCheGWsmBxDLVosaW9iuFxG5IVcd-CgM3k");
        }else{
            setDefaultLocation("https://www.delabahia.com.ar/wp-content/uploads/2019/09/6kaed81k-2.jpg");
        }
    })

      const callWhatsApp = () =>{
        window.open("https://api.whatsapp.com/send?phone=51"+company.phone+"&text=Hola%20"+company.name, "_blank");
      }
      const callFacebook = () =>{
        window.open(company.facebook, "_blank");
      }
      const callYoutube = () =>{
        window.open(company.youtube, "_blank");
      }
    return (
        <>
            {company && (<Box my={4}>
                <Container>
                    <Typography variant="h1" component="h2" ml={2}>
                        {company.name}
                    </Typography>
                    <Typography variant="h5" component="h3" ml={2}>
                        {company.sector?.name}
                    </Typography>
                    <Grid container spacing={2} mt={3}>
                        <Grid item={true} md={4} xs={12}>
                            <Box>
                                <img width='auto' style={{margin: 'auto', padding:'1rem'}} height='250px' src={company.profilepic || (company.isinvestor ? defaultInvestorImage : defaultStartupImage)}/>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant="h2" component="h3" sx={{ display: 'flex', alignItems:'center'}}>
                                <Avatar sx={{ bgcolor: 'rgb(1, 203, 187)', marginRight: '1rem'}} ><FingerprintOutlinedIcon sx={{color:'white'}}/> </Avatar> Información Relevante
                            </Typography>
                            <Box mt={2} pl={2}>
                                {parse(company.relevantInformations?company.relevantInformations:'')}
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant="h2" component="h3" sx={{ display: 'flex', alignItems:'center'}}>
                                <Avatar sx={{ bgcolor: 'rgb(1, 203, 187)', marginRight: '1rem'}} ><LocationOn sx={{color:'white'}}/> </Avatar> Ubicanos
                            </Typography>
                            <Box mt={2} sx={{opacity:'0.9'}}>
                            {parse(company.locations?.length ?
                                '<img width="auto" height="300" src="https://maps.googleapis.com/maps/api/staticmap?center='+company.locations[0].latitude+','+company.locations[0].longitude+'&zoom='+company.locations[0].cooom+'&size=300x300&markers=color:red%7Clabel:S%7C'+company.locations[0].latitude+','+company.locations[0].longitude+'&key=AIzaSyCheGWsmBxDLVosaW9iuFxG5IVcd-CgM3k" title="'+company.name+'" alt="'+company.name+'"/>'
                                    : '<img width="auto" height="200" src="https://www.delabahia.com.ar/wp-content/uploads/2019/09/6kaed81k-2.jpg"/>'
                                )}
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant="h2" component="h3" sx={{ display: 'flex', alignItems:'center'}}>
                                <Avatar sx={{ bgcolor: 'rgb(1, 203, 187)', marginRight: '1rem'}} ><PersonOutlineOutlined sx={{color:'white'}}/> </Avatar> Quienes somos
                            </Typography>
                            <Box mt={2}>
                                {parse(company.description?company.description:'')}
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant="h2" component="h3" sx={{ display: 'flex', alignItems:'center'}}>
                                <Avatar sx={{ bgcolor: 'rgb(1, 203, 187)', marginRight: '1rem'}} ><TroubleshootOutlined sx={{color:'white'}}/> </Avatar> ¿Qué buscamos?
                            </Typography>
                            <Box mt={2}>
                                {parse(company.lookingFor?company.lookingFor:'')}
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant="h2" component="h3" sx={{ display: 'flex', alignItems:'center'}}>
                                <Avatar sx={{ bgcolor: 'rgb(1, 203, 187)', marginRight: '1rem'}} ><ContactPageOutlined sx={{color:'white'}}/> </Avatar> Contacto
                            </Typography>
                            <Box mt={2} px={3} py={2} sx={{backgroundColor: '#f2f2f2',borderRadius:'1rem'}}>
                                {company.phone?
                                <Typography onClick={() => {callWhatsApp()}} variant="h3" component="h3" sx={{cursor:'pointer', margin:'auto',color:'white',maxWidth:'180px',display: 'flex', bgcolor: '#25D366', borderRadius:'0.5rem', justifyContent:'center',alignItems:'center'}}>
                                    <Avatar sx={{  bgcolor: '#25D366'}} ><WhatsApp sx={{color:'white'}}/> </Avatar> WhatsApp
                                </Typography>:''
                                }
                                <Typography  variant="h3" component="h3" sx={{cursor:'pointer', margin:'auto',marginTop:'1rem',display: 'flex', justifyContent:'center',alignItems:'center'}}>
                                {company.facebook?
                                    <Avatar  onClick={() => {callFacebook()}} sx={{  bgcolor: 'transparent',width: 56, height: 56 }} >
                                        <Facebook sx={{ width: 48, height: 48 }}></Facebook> 
                                    </Avatar> :''
                                }
                                {company.youtube?
                                    <Avatar  onClick={() => {callYoutube()}} sx={{  bgcolor: 'transparent', marginLeft:'1rem',width: 56, height: 56 }} >
                                        <YouTube sx={{color:'red',width: 48, height: 48 }}></YouTube> 
                                    </Avatar>:''
                                }
                                </Typography>
                                {company.website?
                                <Typography variant="h5" component="h6" sx={{ margin:'auto',marginTop:'0.5rem',display: 'flex', justifyContent:'center',alignItems:'center'}}>
                                    <a style={{textDecoration:'none'}} href={company.website} target="_blank">{company.website}</a>
                                </Typography>:''
                                }
                                {company.email?
                                <Typography variant="h5" component="h6" sx={{ margin:'auto',marginTop:'0.75rem',display: 'flex', justifyContent:'center',alignItems:'center'}}>
                                    <a style={{textDecoration:'none', color:'black'}} href={"mailto:"+ company.email} target="_blank">{company.email}</a>
                                </Typography>:''
                                }
                            </Box>                        
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant="h2" component="h3" sx={{ display: 'flex', alignItems:'center'}}>
                                <Avatar sx={{ bgcolor: 'rgb(1, 203, 187)', marginRight: '1rem'}} ><StarOutlined sx={{color:'white'}}/> </Avatar> Soluciones
                            </Typography>
                            <Box mt={2}>
                                {parse(company.products?company.products:'')}
                            </Box>  
                        </Grid>
                    </Grid>
                </Container>
                <Box mt={4}>
                    <Container>
                        <Stack direction={'column'} alignItems={'center'} spacing={3} style={{width: '100%', paddingTop: '20px'}}>
                            <Stack direction={'row'} spacing={6}>
                            <Typography variant="h1" component="h2" ml={2}>
                                Compañias afines
                            </Typography>
                            </Stack>
                            <RoundedTable  border='2px solid #01cbbb' borderRadius="25px">
                                <tr style={{...trStyle, backgroundColor: '#01cbbb', color: 'white'}}>
                                    <th style={thStyle}>Startup</th>
                                    <th style={thStyle}>Logo</th>
                                    <th style={thStyle}>Industria</th>
                                    <th style={thStyle}>Tags</th>
                                </tr>
                                {companies?.map(item => (
                                        <tr style={trStyle} key={item.id}  onClick={() => {navigate(`/companyDetail/${item.id}`)}}>
                                            <td style={tdStyle}>{item.name}</td>
                                            <td style={tdStyle}><img width='auto' style={{margin: 'auto'}} height='100px' src={item.profilepic || defaultStartupImage}/></td>
                                            <td style={tdStyle}>{item.sector?.name}</td>
                                            <td style={tdStyle}>
                                                    {item.tags?.map(tag => tag + ' ')}
                                            </td>
                                        </tr>
                                    ))}
                            </RoundedTable>
                        </Stack>
                    </Container>
                </Box>
            </Box>
            )
        }
        </>
    )
}