import HubTypeRepository from "@common/domain/data/HubTypeRepository";
import HubType from "@common/domain/entities/HubType";
import User from "@common/domain/entities/User";
import { hubType } from "./InitData";
import { v4 as uuidv4 } from 'uuid';

export default class MockHubTypeRepository implements HubTypeRepository{

    private static hubtypes: HubType[] = hubType;

    async saveHubType(user: User, hubType: HubType): Promise<HubType> {
        if (!hubType.id){
            hubType.id = uuidv4();
            MockHubTypeRepository.hubtypes.push(hubType);
            return hubType;
        }else{
            const record = MockHubTypeRepository.hubtypes.find(record => record.id === hubType.id);
            if (record){
                record.name = hubType.name;
                record.status = hubType.status;
            }
            return record as HubType;
        }
    }
    async getHubTypes(user: User): Promise<HubType[]> {
        return MockHubTypeRepository.hubtypes;
    }
    async deleteHubType(user: User, hubType: HubType): Promise<boolean> {
        const record = MockHubTypeRepository.hubtypes.find(record => record.id === hubType.id);
        if (record){
            MockHubTypeRepository.hubtypes = MockHubTypeRepository.hubtypes.filter(record => record.id !== hubType.id)
            return true;
        }
        return false;
    }

}