import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import { useState } from 'react';

export enum Icon {
    NONE,
    LEFT,
    RIGHT
};

export default function SearchInput({style={}, icon = Icon.NONE, backgroundColor, color, onSearch}: {style: any, icon: Icon, backgroundColor: string, color: string, onSearch: (event: any) => void} ) {
    const [value, setValue] = useState("");
    const wrapperStyle = {
        ...style,
        borderRadius: '36px',
        overflow: 'hidden',
        background: backgroundColor,
        border: '1px solid ' + backgroundColor,
        paddingRight: icon === Icon.RIGHT ? '15px' : '0px'
    };
    const inputStyle = {
        paddingLeft: icon === Icon.LEFT ? '0px' : '15px',
        width: '140px',
        height: '40px',
        border: '0px',
        outline: 'none',
        backgroundColor,
        color
    };
    const iconStyle = {
        marginLeft: '10px',
        marginTop: '10px',

        color
    };
    const liveSearch = (search:string) =>{
        setValue(search);
        onSearch(search);
    }
    return (
        <Stack direction={'row'} style={wrapperStyle}>
            {icon === Icon.LEFT && (
                <span onClick={()=>onSearch(value)}><SearchIcon style={iconStyle} /></span>
            )}
            <input style={inputStyle} value={value} onChange={e=>(
                //setValue(e.target.value
                liveSearch(e.target.value)
                )} onKeyDown={e => {
                if (e.keyCode === 13){
                    onSearch(value);
                }
            }} />
            {icon === Icon.RIGHT && (
                <span onClick={()=>onSearch(value)}><SearchIcon style={{...iconStyle}} /></span>
            )}
        </Stack>
    );
};