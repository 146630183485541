import OrganizationRepository from "../data/OrganizationRepository";
import PermissionRepository from "../data/PermissionRepository";
import PermissionTypeRepository from "../data/PermissionTypeRepository";
import RoleRepository from "../data/RoleRepository";
import UserRepository from "../data/UserRepository";
import Organization from "../entities/Organization";
import Permission from "../entities/Permission";
import PermissionType from "../entities/PermissionType";
import Role from "../entities/Role";
import User from "../entities/User";

export default class AdministratorInteractor {
    
    private organizationRepository: OrganizationRepository;
    private permissionRepository: PermissionRepository;
    private permissionTypeRepository: PermissionTypeRepository;
    private roleRepository: RoleRepository;
    private userRepository: UserRepository;
    

    constructor(organizationRepository: OrganizationRepository, permissionRepository: PermissionRepository, 
        permissionTypeRepository: PermissionTypeRepository, roleRepository: RoleRepository, userRepository: UserRepository){
        this.organizationRepository = organizationRepository;
        this.permissionRepository = permissionRepository;
        this.permissionTypeRepository = permissionTypeRepository;
        this.roleRepository = roleRepository;
        this.userRepository = userRepository;
    }

    public async getOrganizations(user: User) : Promise<Organization[]>{
        //only for Paisley Admin users
        return this.organizationRepository.getOrganizations(user);
    }

    public async saveUser(user: User, userObject: User): Promise<User>{
        //TODO: Generate a Signatory on backend everytime we regsiter a user
        return this.userRepository.saveUser(user, userObject);
    }
    public async getUsers(user: User) : Promise<User[]>{
        return this.userRepository.getUsers(user);
    }
    public async deleteUser(user: User, userObject: User): Promise<Boolean>{
        return this.userRepository.deleteUser(user, userObject);
    }

    public async getRoles(user: User): Promise<Role[]> {
        return this.roleRepository.getRoles(user);
    }
    public async getPermissionTypes(user: User): Promise<PermissionType[]> {
        return this.permissionTypeRepository.getPermissionTypes(user);
    }

    public async savePermission(user: User, permission: Permission): Promise<Permission> {
        return this.permissionRepository.savePermission(user, permission);
    }
}