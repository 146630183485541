import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from '../../../../../../config';
import Logo from '../../../widgets/Logo';
import { menuSelect } from '../../../../../redux/actions/session';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => 'defaultId');
  const dispatch = useDispatch();
  return (
    <ButtonBase disableRipple onClick={
      () => {
        dispatch(menuSelect(defaultId))
      }
      } component={Link} to={config.defaultPath}>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
