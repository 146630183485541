export default class CompanyLocation {
    private _id: string | undefined;
    private _latitude: string | undefined;
    private _longitude: string;
    private _zoom: number;
    
    constructor(latitude: string | undefined, longitude: string, zoom: number) {
        this._latitude = latitude;
        this._longitude = longitude;
        this._zoom = zoom;
    }
    public get id(): string | undefined {
        return this._id;
    }
    public set id(value: string | undefined) {
        this._id = value;
    }
    public get latitude(): string | undefined {
        return this._latitude;
    }
    public set latitude(value: string | undefined) {
        this._latitude = value;
    }
    public get longitude(): string {
        return this._longitude;
    }
    public set longitude(value: string) {
        this._longitude = value;
    }
    public get zoom(): number {
        return this._zoom;
    }
    public set zoom(value: number) {
        this._zoom = value;
    }
}