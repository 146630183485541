import UserRepository from "@common/domain/data/UserRepository";
import User from "@common/domain/entities/User";
import { users } from "./InitData";
import { v4 as uuidv4 } from 'uuid';

export default class MockUserRepository implements UserRepository{
    
    private static users: User[] = users;

    async saveUser(user: User, userObject: User): Promise<User> {
        if (!userObject.id){
            userObject.id = uuidv4();
            userObject.organization = MockUserRepository.users[0].organization;
            MockUserRepository.users.push(userObject);
            return userObject;
        }else{
            const record = MockUserRepository.users.find(record => record.id === userObject.id);
            if (record){
                record.dateOfBirth = userObject.dateOfBirth;
                record.email = userObject.email;
                record.firstName = userObject.firstName;
                record.lastName = userObject.lastName;
                record.middleName = userObject.middleName;
                //record.organization = userObject.organization;
                record.role = userObject.role;
                record.username = userObject.username;
            }
            return record as User;
        }
    }
    async deleteUser(user: User, userObject: User): Promise<Boolean> {
        const record = MockUserRepository.users.find(record => record.id === userObject.id);
        if (record){
            MockUserRepository.users = MockUserRepository.users.filter(record => record.id !== userObject.id)
            return true;
        }
        return false;
    }
    async getUsers(user: User): Promise<User[]> {
        return MockUserRepository.users;
    }
    async login(username: string, password: string): Promise<User | undefined> {
        return MockUserRepository.users.find(user => user.username === username);
        //return MockUserRepository.users[0];
    }
    async requestPasswordUpdate(email: string): Promise<boolean> {
        return true;
    }
    async passwordUpdate(username: string, password: string, otp: string): Promise<User | undefined> {
        return MockUserRepository.users[0];
    }
    
}