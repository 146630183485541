import React from 'react'
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import AddIcon from '@mui/icons-material/Add';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Fab, Grid, Stack, Tooltip, Typography } from '@mui/material';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(({
  addCallback,
  border = true,
  boxShadow,
  children,
  content = true,
  contentClass = '',
  contentSX = {},
  darkTitle,
  secondary,
  shadow,
  sx = {},
  title,
  ...others
  }, ref) => {
    const theme = useTheme();
    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary[200] + 25,
          ':hover': {
            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
          },
          ...sx
        }}
      >
        {/* card header and action */}
        {title && 
          <Grid container spacing={2}>
            <Grid item xs={addCallback ? 10 : 12}>
              <CardHeader sx={headerSX} title={darkTitle ? <Typography variant="h3">{title}</Typography> : title} action={secondary} />
            </Grid>
            {addCallback && 
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="end" style={{marginRight: '25px', marginTop: '15px'}}>
                  <Tooltip title="Add">
                    <Fab size="small" color="secondary" variant='extended' aria-label="add" onClick={addCallback}>
                      <AddIcon sx={{ mr: 1 }} /> Añadir &nbsp;
                    </Fab>
                  </Tooltip>
                </Stack>
              </Grid>
            }
          </Grid>
        }
        
        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  addCallback: PropTypes.func,
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
