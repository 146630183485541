import Employee from "./Employee";
import Hub from "./Hub";
import Sector from "./Sector";
import CompanyLocation from "./CompanyLocation";
export enum FundingStage {
    Initial = 'Initial',
    InProgress = 'In Progress',
    Completed = 'Completed'
}
export enum BusinessModel{
    B2B = 'Business to Business',
    B2C = 'Business to Customer',
}

export enum State{
    TRU = 'Trujillo',
    CHE = 'Chepén',
    PAC = 'Pacasmayo',
    ASC = 'Ascope',
    VIR = 'Virú',
    GRC = 'Gran Chimu',
    OTU = 'Otuzco',
    JUL = 'Julcán',
    STGO = 'Santiago de Chuco',
    SCHC = 'Sánchez Carrión',
    BOL = 'Bolivar',
    PAT = 'Pataz'
}
export default class Company{
    private _id: string | undefined;
    private _name: string;
    private _address: string;
    private _phone: string;
    private _email: string;
    private _website: string;
    private _profilepic: string;
    private _description: string;
    private _mision: string;
    private _vision: string;
    private _status: boolean = false;
    private _milestones: string[] = [];
    private _founded: Date;
    private _isstartup: boolean = false;
    private _products: string | undefined;
    private _locations: CompanyLocation[] = [];
    private _tags: string[] = [];
    private _sector: Sector | undefined;
    private _hub: Hub | undefined;
    private _employee: Employee | undefined;
    private _fundingstage: string | undefined;
    private _businessmodel: string | undefined;
    private _isinvestor: boolean = false;
    private _city: string | undefined;
    private _state: string | undefined;
    private _country: string | undefined;
    private _relevantInformations: string | undefined;
    private _hiddenInformations: string | undefined;
    private _lookingFor: string | undefined;
    private _facebook: string | undefined;
    private _youtube: string | undefined;
    private _linkedin: string | undefined;
    private _instagram: string | undefined;
    
    constructor(id: string | undefined, name: string, address: string, phone: string, email: string, website: string, profilepic: string, 
        description: string, mision: string, vision: string, status: boolean, founded: Date ){
        this._id = id;
        this._name = name;
        this._address = address;
        this._phone = phone;
        this._email = email;
        this._website = website;
        this._profilepic = profilepic;
        this._description = description;
        this._mision = mision;
        this._vision = vision;
        this._founded = founded;
        this._status = status;
    }

    public get id(): string | undefined {
        return this._id;
    }
    public set id(value: string | undefined) {
        this._id = value;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get address(): string {
        return this._address;
    }
    public set address(value: string) {
        this._address = value;
    }
    public get phone(): string {
        return this._phone;
    }
    public set phone(value: string) {
        this._phone = value;
    }
    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }
    public get website(): string {
        return this._website;
    }
    public set website(value: string) {
        this._website = value;
    }
    public get profilepic(): string {
        return this._profilepic;
    }
    public set profilepic(value: string) {
        this._profilepic = value;
    }
    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }
    public get mision(): string {
        return this._mision;
    }
    public set mision(value: string) {
        this._mision = value;
    }
    public get vision(): string {
        return this._vision;
    }
    public set vision(value: string) {
        this._vision = value;
    }
    public get status(): boolean {
        return this._status;
    }
    public set status(value: boolean) {
        this._status = value;
    }
    public get milestones(): string[] {
        return this._milestones;
    }
    public set milestones(value: string[]) {
        this._milestones = value;
    }
    public get founded(): Date {
        return this._founded;
    }
    public set founded(value: Date) {
        this._founded = value;
    }
    public get isstartup(): boolean {
        return this._isstartup;
    }
    public set isstartup(value: boolean) {
        this._isstartup = value;
    }
    public get products(): string | undefined {
        return this._products;
    }
    public set products(value: string | undefined) {
        this._products = value;
    }
    public get locations(): CompanyLocation[] {
        return this._locations;
    }
    public set locations(value: CompanyLocation[]) {
        this._locations = value;
    }
    public get tags(): string[] {
        return this._tags;
    }
    public set tags(value: string[]) {
        this._tags = value;
    }
    public get sector(): Sector | undefined {
        return this._sector;
    }
    public set sector(value: Sector | undefined) {
        this._sector = value;
    }
    public get hub(): Hub | undefined {
        return this._hub;
    }
    public set hub(value: Hub | undefined) {
        this._hub = value;
    }
    public get employee(): Employee | undefined {
        return this._employee;
    }
    public set employee(value: Employee | undefined) {
        this._employee = value;
    }
    public get fundingstage(): string | undefined {
        return this._fundingstage;
    }
    public set fundingstage(value: string | undefined) {
        this._fundingstage = value;
    }
    public get businessmodel(): string | undefined {
        return this._businessmodel;
    }
    public set businessmodel(value: string | undefined) {
        this._businessmodel = value;
    }
    public get isinvestor(): boolean {
        return this._isinvestor;
    }
    public set isinvestor(value: boolean) {
        this._isinvestor = value;
    }
    public get city(): string | undefined {
        return this._city;
    }
    public set city(value: string | undefined) {
        this._city = value;
    }
    public get state(): string | undefined {
        return this._state;
    }
    public set state(value: string | undefined) {
        this._state = value;
    }
    public get country(): string | undefined {
        return this._country;
    }
    public set country(value: string | undefined) {
        this._country = value;
    }
    public get relevantInformations(): string | undefined {
        return this._relevantInformations;
    }
    public set relevantInformations(value: string | undefined) {
        this._relevantInformations = value;
    }
    public get hiddenInformations(): string | undefined {
        return this._hiddenInformations;
    }
    public set hiddenInformations(value: string | undefined) {
        this._hiddenInformations = value;
    }
    public get lookingFor(): string | undefined {
        return this._lookingFor;
    }
    public set lookingFor(value: string | undefined) {
        this._lookingFor = value;
    }
    public get facebook(): string | undefined {
        return this._facebook;
    }
    public set facebook(value: string | undefined) {
        this._facebook = value;
    }
    public get youtube(): string | undefined {
        return this._youtube;
    }
    public set youtube(value: string | undefined) {
        this._youtube = value;
    }
    public get linkedin(): string | undefined {
        return this._linkedin;
    }
    public set linkedin(value: string | undefined) {
        this._linkedin = value;
    }
    public get instagram(): string | undefined {
        return this._instagram;
    }
    public set instagram(value: string | undefined) {
        this._instagram = value;
    }

       
}