import React from 'react'
import Footer from './Footer';
import Header from './Header';
import { CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

// ==============================|| FRONT LAYOUT ||============================== //
const FrontLayout = () => (
  <>
    <CssBaseline />
    <Header />
    <Outlet />
    <Footer />
  </>
);

export default FrontLayout;
