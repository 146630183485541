import Company from "./Company";
export default class News {
    private _id: string | undefined;
    private _source: string;
    private _title: string;
    private _subtitle: string;
    private _content: string;
    private _date: Date;
    private _company: Company | undefined;
    private _image: string | undefined;
    private _status: boolean | undefined;
    private _isEvent: boolean | undefined;


    constructor(id: string | undefined, source: string, content: string, title: string, subtitle: string,date:Date, company: Company, image: string | undefined, isEvent: boolean | undefined) {
        this._id = id;
        this._source = source;
        this._content = content;
        this._title = title;
        this._subtitle = subtitle;
        this._date = date;
        this._company = company;
        this._image = image;
        this._isEvent = isEvent;
    }
    public get source(): string {
        return this._source;
    }
    public set source(value: string) {
        this._source = value;
    }
    public get title(): string {
        return this._title;
    }
    public set title(value: string) {
        this._title = value;
    }
    public get subtitle(): string {
        return this._subtitle;
    }
    public set subtitle(value: string) {
        this._subtitle = value;
    }
    public get date(): Date {
        return this._date;
    }
    public set date(value: Date) {
        this._date = value;
    }
    public get content(): string {
        return this._content;
    }
    public set content(value: string) {
        this._content = value;
    }
    public get id(){ return this._id; }
    public set id(id: string|undefined){ this._id = id; }
    public get status(): boolean | undefined {
        return this._status;
    }
    public set status(value: boolean | undefined) {
        this._status = value;
    }
    public get image(): string | undefined {
        return this._image;
    }
    public set image(value: string | undefined) {
        this._image = value;
    }
    public get company(): Company | undefined {
        return this._company;
    }
    public set company(value: Company | undefined) {
        this._company = value;
    }
    public get isEvent(): boolean | undefined {
        return this._isEvent;
    }
    public set isEvent(value: boolean | undefined) {
        this._isEvent = value;
    }
}