import OrganizationRepository from "@common/domain/data/OrganizationRepository";
import Organization from "@common/domain/entities/Organization";
import User from "@common/domain/entities/User";
import { organizations } from "./InitData";

export default class MockOrganizationRepository implements OrganizationRepository{

    async getOrganizations(user: User): Promise<Organization[]> {
        return organizations;
    }

}