import administrator from './administrator';
import home from './home';
import requestor from './requestor';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [home, administrator, requestor]
};

export default menuItems;
