import UserRepository from "@common/domain/data/UserRepository";
import User from "@common/domain/entities/User";

export default class APIUserRepository implements UserRepository{
    deleteUser(user: User, userObject: User): Promise<Boolean> {
        throw new Error("Method not implemented.");
    }
    saveUser(user: User, userObject: User): Promise<User> {
        throw new Error("Method not implemented.");
    }
    getUsers(user: User): Promise<User[]> {
        throw new Error("Method not implemented.");
    }
    login(username: string, password: string): Promise<User | undefined> {
        throw new Error("Method not implemented.");
    }
    requestPasswordUpdate(email: string): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    passwordUpdate(username: string, password: string, otp: string): Promise<User | undefined> {
        throw new Error("Method not implemented.");
    }
    
}