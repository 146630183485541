export enum OrganizationStatus {
    ACTIVE,
    UPDATED,
    INACTIVE,
    DELETED
}

export type Organization = {
    id?: string;
    name: string;
    description: string;
    status: OrganizationStatus;
}
export default Organization;