import User from "@common/domain/entities/User";
import { v4 as uuidv4 } from 'uuid';
import HubRepository from "@common/domain/data/HubRepository";
import Hub from "@common/domain/entities/Hub";
import HubType from "@common/domain/entities/HubType";
import { hubs } from "./InitData";
import Sector from "@common/domain/entities/Sector";

export default class MockHubRepository implements HubRepository {

    private static hubs: Hub[] = hubs;

    async saveHub(user: User, hub: Hub): Promise<Hub> {
        if (!hub.id){
            hub.id = uuidv4();
            MockHubRepository.hubs.push(hub);
            return hub;
        }else{
            const record = MockHubRepository.hubs.find(record => record.id === hub.id);
            if (record){
                record.description = hub.description;
                record.name = hub.name;
                record.hubType = hub.hubType;
                record.status = hub.status;
                record.email = hub.email;
                record.phone = hub.phone;
                record.website = hub.website;
                record.sector = hub.sector;
                record.profilepic = hub.profilepic;
                record.relevantInformations = hub.relevantInformations;
                record.lookingFor = hub.lookingFor;
                record.facebook = hub.facebook;
                record.youtube = hub.youtube;
                record.linkedin = hub.linkedin;
                record.instagram = hub.instagram;
            }
            return record as Hub;
        }
    }
    async deleteHub(user: User, hub: Hub): Promise<boolean> {
        const record = MockHubRepository.hubs.find(record => record.id === hub.id);
        if (record){
            MockHubRepository.hubs = MockHubRepository.hubs.filter(record => record.id !== hub.id)
            return true;
        }
        return false;
    }
    async getHubs(user: User,name?: string, hubType?: HubType, sector?: Sector): Promise<Hub[]> {
        if (name !== undefined){
            if (hubType !== undefined && sector !== undefined){
                return MockHubRepository.hubs.filter(hub => hub.name.toLowerCase().toLowerCase().includes(name.toLowerCase()) && 
                                                                hub.hubType.id === hubType.id &&
                                                                hub.sector.id === sector.id);
            }else if (hubType !== undefined){
                return MockHubRepository.hubs.filter(hub => hub.name.toLowerCase().toLowerCase().includes(name.toLowerCase()) && 
                                                        hub.hubType.id === hubType.id);
            }else if (sector !== undefined){
                return MockHubRepository.hubs.filter(hub => hub.name.toLowerCase().toLowerCase().includes(name.toLowerCase()) && 
                                                        hub.sector.id === sector.id);
            }else{
                return MockHubRepository.hubs.filter(hub => hub.name.toLowerCase().toLowerCase().includes(name.toLowerCase()));
            }
        }else{
            if (hubType !== undefined && sector !== undefined){
                return MockHubRepository.hubs.filter(hub => hub.hubType.id === hubType.id &&
                                                            hub.sector.id === sector.id);
            }else if (hubType !== undefined){
                return MockHubRepository.hubs.filter(hub => hub.hubType.id === hubType.id);
            }else if (sector !== undefined){
                return MockHubRepository.hubs.filter(hub => hub.sector.id === sector.id);
            }
        }
        return MockHubRepository.hubs;
    }
    async getHubById(hubId: string): Promise<Hub | undefined> {
        const record = MockHubRepository.hubs.find(record => record.id === hubId);
        console.log("MockHubRepository getHubById", record);
        return record;
    }

}