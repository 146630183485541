import React from 'react'
import { lazy } from 'react';

// project imports
import MainLayout from '../theme/layout/MainLayout';
import Loadable from '../theme/widgets/Loadable';
import { ProtectedRoute } from './ProtectedRoute';
import { RoleLevel } from '@common/domain/entities/Role';

// dashboard routing
const HubType = Loadable(lazy(() => import('../pages/editor/HubType')));
const Sector = Loadable(lazy(() => import('../pages/editor/Sector')));
const Tag = Loadable(lazy(() => import('../pages/editor/Tag')));
const Hub = Loadable(lazy(() => import('../pages/editor/Hub')));
const Company = Loadable(lazy(() => import('../pages/editor/Company')));
const News = Loadable(lazy(() => import('../pages/editor/News')));

// ==============================|| MAIN ROUTING ||============================== //

const EditorRoutes = {
  path: '/',
  element: <ProtectedRoute level={RoleLevel.Editor}><MainLayout /></ProtectedRoute>,
  children: [
    { path: 'hubtypes', element: <HubType /> },
    { path: 'sectors', element: <Sector /> },
    { path: 'tags', element: <Tag /> },
    { path: 'hubs', element: <Hub />},
    { path: 'companies', element: <Company />},
    { path: 'news', element: <News />},
  ]
};

export default EditorRoutes;