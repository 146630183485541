import HubType from "./HubType";
import Sector from "./Sector";

export default class Hub {
    private _id: string | undefined;
    private _name: string;
    private _description: string;
    private _phone: string | undefined;
    private _email: string;
    private _website: string | undefined;

    private _hubType: HubType;
    private _status: boolean;
    private _profilepic: string | undefined;
    private _relevantInformations: string | undefined;
    private _lookingFor: string | undefined;
    private _facebook: string | undefined;
    private _youtube: string | undefined;
    private _linkedin: string | undefined;
    private _instagram: string | undefined;
    private _sector: Sector;

 

    constructor(id: string, name: string, description: string, hubType: HubType, 
        status: boolean,phone: string | undefined,email: string,website: string | undefined,
        profilepic: string | undefined, relevantInformations: string | undefined,
        lookingFor: string | undefined, facebook: string | undefined,youtube: string,
        linkedin: string | undefined, instagram: string | undefined, sector: Sector) {
        this._id = id;
        this._name = name;
        this._description = description;
        this._hubType = hubType;
        this._status = status;
        this._profilepic = profilepic;
        this._phone = phone;
        this._linkedin = linkedin;
        this._youtube = youtube;
        this._facebook = facebook;
        this._email = email;
        this._website = website;
        this._relevantInformations = relevantInformations;
        this._lookingFor = lookingFor;
        this._instagram = instagram;
        this._sector = sector;
    }
    
    public get id(): string | undefined {
        return this._id;
    }
    public set id(value: string | undefined) {
        this._id = value;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }
    public get hubType(): HubType {
        return this._hubType;
    }
    public set hubType(value: HubType) {
        this._hubType = value;
    }
    public get status(): boolean {
        return this._status;
    }
    public set status(value: boolean) {
        this._status = value;
    }
    
    public get phone(): string | undefined {
        return this._phone;
    }
    public set phone(value: string | undefined) {
        this._phone = value;
    }
    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }
    public get website(): string | undefined {
        return this._website;
    }
    public set website(value: string | undefined) {
        this._website = value;
    }
    public get profilepic(): string | undefined {
        return this._profilepic;
    }
    public set profilepic(value: string | undefined) {
        this._profilepic = value;
    }
    public get relevantInformations(): string | undefined {
        return this._relevantInformations;
    }
    public set relevantInformations(value: string | undefined) {
        this._relevantInformations = value;
    }
    public get lookingFor(): string | undefined {
        return this._lookingFor;
    }
    public set lookingFor(value: string | undefined) {
        this._lookingFor = value;
    }
    public get facebook(): string | undefined {
        return this._facebook;
    }
    public set facebook(value: string | undefined) {
        this._facebook = value;
    }
    public get youtube(): string | undefined {
        return this._youtube;
    }
    public set youtube(value: string | undefined) {
        this._youtube = value;
    }
    public get linkedin(): string | undefined {
        return this._linkedin;
    }
    public set linkedin(value: string | undefined) {
        this._linkedin = value;
    }
    public get instagram(): string | undefined {
        return this._instagram;
    }
    public set instagram(value: string | undefined) {
        this._instagram = value;
    }
    public get sector(): Sector {
        return this._sector;
    }
    public set sector(value: Sector) {
        this._sector = value;
    }
}