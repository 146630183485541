import NewsRepository from "@common/domain/data/NewsRepository";
import News from "@common/domain/entities/News";
import User from "@common/domain/entities/User";
import { v4 as uuidv4 } from 'uuid';
import { news } from "./InitData";

export default class MockNewsRepository implements NewsRepository{

    private static news: News[] = news;

    async saveNews(user: User, news: News): Promise<News> {
        if (!news.id){
            news.id = uuidv4();
            MockNewsRepository.news.push(news);
            return news;
        }else{
            const record = MockNewsRepository.news.find(record => record.id === news.id);
            if (record){
                record.source = news.source;
                record.title = news.title;
                record.content = news.content;
                record.date = news.date;
                record.subtitle = news.subtitle;
                record.company = news.company;
                record.image = news.image;
                record.status = news.status;
            }
            return record as News;
        }
    }
    async getNews(): Promise<News[]> {
        return MockNewsRepository.news;
    }
    async deleteNews(user: User, news: News): Promise<boolean> {
        const record = MockNewsRepository.news.find(record => record.id === news.id);
        if (record){
            MockNewsRepository.news = MockNewsRepository.news.filter(record => record.id !== news.id);
            return true;
        }
        return false;
    }
}