// assets
import { Campaign, Description, DocumentScanner, FolderCopy, Groups } from '@mui/icons-material';
import { RoleLevel } from '@common/domain/entities/Role';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const editor = {
  id: 'editor',
  title: 'Registro de datos',
  caption: 'Listado de mantenimiento',
  type: 'group',
  level: RoleLevel.Editor,
  children: [{
    id: 'company',
    title: 'Compañias',
    type: 'item',
    icon: Groups,
    url: '/companies',
    breadcrumbs: false
  }, {
    id: 'hub',
    title: 'Entidades de Soporte',
    type: 'item',
    icon: FolderCopy,
    url: '/hubs',
    breadcrumbs: false
  }, {
    id: 'news',
    title: 'Noticias',
    type: 'item',
    icon: Campaign,
    url: '/news',
    breadcrumbs: false
  },{
    id: 'configuration',
    title: 'Configuración',
    type: 'collapse',
    icon: DocumentScanner,
    children: [{
      id: 'tag',
      title: 'Tags',
      type: 'item',
      icon: FolderCopy,
      url: '/tags',
      breadcrumbs: false
    }, {
      id: 'hubtype',
      title: 'Tipos de Hub',
      type: 'item',
      icon: Groups,
      url: '/hubtypes',
      breadcrumbs: false
    }, {
      id: 'sectors',
      title: 'Sectores',
      type: 'item',
      icon: FolderCopy,
      url: '/sectors',
      breadcrumbs: false
    }]
  }]
};

export default editor;
