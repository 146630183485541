import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import config from '../config';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import Routes from './components/routes';
import theme from './components/theme';
import { useSelector } from 'react-redux';
import NavigationScroll from './components/theme/layout/NavigationScroll';
import { AuthProvider } from './hooks/useAuth';

function App() {
  const customization = useSelector((state) => (state as any).customization);

  return (
    <BrowserRouter basename={config.basename}>
      <AuthProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;