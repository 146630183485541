import User from "@common/domain/entities/User";
import { v4 as uuidv4 } from 'uuid';
import Sector from "@common/domain/entities/Sector";
import CompanyRepository from "@common/domain/data/CompanyRepository";
import Company,{BusinessModel} from "@common/domain/entities/Company";
import { companies } from "./InitData";

export default class MockCompanyRepository implements CompanyRepository {

    private static companies: Company[] = companies;

    async saveCompany(user: User, company: Company): Promise<Company> {
        if (!company.id){
            company.id = uuidv4();
            console.log(company);
            MockCompanyRepository.companies.push(company);
            return company;
        }else{
            const record = MockCompanyRepository.companies.find(record => record.id === company.id);
            if (record){
                console.log("record edit: ", record);
                record.description = company.description;
                record.name = company.name;
                record.address = company.address;
                record.phone = company.phone;
                record.email = company.email;
                record.website = company.website;
                record.hub = company.hub;
                record.status = company.status;
                record.profilepic = company.profilepic;
                record.mision = company.mision;
                record.vision = company.vision;
                record.founded = company.founded;
                record.isstartup = company.isstartup;
                record.isinvestor = company.isinvestor;
                record.businessmodel = company.businessmodel;
                record.fundingstage = company.fundingstage;
                record.sector = company.sector;
                record.locations = company.locations;
                record.relevantInformations = company.relevantInformations; // should
                record.hiddenInformations = company.hiddenInformations; // should be hidden
                record.lookingFor = company.lookingFor;
                record.facebook = company.facebook;
                record.youtube = company.youtube;
                record.linkedin = company.linkedin;
                record.instagram = company.instagram;
                record.products = company.products;
                record.tags = company.tags;
                record.state = company.state;

            }
            return record as Company;
        }
    }
    async deleteCompany(user: User, company: Company): Promise<boolean> {
        const record = MockCompanyRepository.companies.find(record => record.id === company.id);
        if (record){
            MockCompanyRepository.companies = MockCompanyRepository.companies.filter(record => record.id !== company.id)
            return true;
        }
        return false;
    }
    async getCompanies(user: User, name?: string, sector?: Sector,isstartup?:boolean,isinvestor?:boolean, state?:string): Promise<Company[]> {
        console.log("MockCompanyRepository getCompanies",name);
        if (name !== undefined && isstartup !== undefined){
            if (sector !== undefined){
                console.log("name with sector");
                return MockCompanyRepository.companies.filter(company => company.name.toLowerCase().toLowerCase().includes(name.toLowerCase()) && company.isstartup == true && company.sector?.id == sector.id);
            }else{
                console.log("name without sector");
                return MockCompanyRepository.companies.filter(company => company.name.toLowerCase().includes(name.toLowerCase()) && company.isstartup == true);
            }
        }else if (name !== undefined && isinvestor !== undefined){
            if (sector !== undefined){
                return MockCompanyRepository.companies.filter(company => company.name.toLowerCase().includes(name.toLowerCase()) && company.isinvestor == true && company.sector?.id == sector.id);
            }else{
                return MockCompanyRepository.companies.filter(company => company.name.toLowerCase().includes(name.toLowerCase()) && company.isinvestor == true);
            }
        }else if (sector !== undefined || isinvestor !== undefined || isstartup !== undefined){
            if (sector !== undefined){
                if (isstartup !== undefined){
                    return MockCompanyRepository.companies.filter(company => company.isstartup == true && company.sector?.id == sector.id);
                }else{
                    return MockCompanyRepository.companies.filter(company => company.isinvestor == true && company.sector?.id == sector.id);
                }
            }else{
                if (isstartup !== undefined){
                    if (state !== undefined){
                        return MockCompanyRepository.companies.filter(company => company.isstartup == true && company.state == state);
                    }else{
                        return MockCompanyRepository.companies.filter(company => company.isstartup == true);
                    }
                }else{
                    if(state !== undefined){
                        return MockCompanyRepository.companies.filter(company => company.isinvestor == true && company.state == state);
                    }else{
                        return MockCompanyRepository.companies.filter(company => company.isinvestor == true);
                    }
                }
            }
        } else if(name !== undefined){
            if (name.length > 0){
                console.log("MockCompanyRepository contains", MockCompanyRepository.companies.filter(company => company.name.toLowerCase().toLowerCase().includes(name.toLowerCase())));
                return MockCompanyRepository.companies.filter(company => company.name.toLowerCase().toLowerCase().includes(name.toLowerCase()));
            }else{
                return [];
            }
        } else{
            return MockCompanyRepository.companies;
        }
    }
    async getCompanyById(companyId: string): Promise<Company | undefined> {
        const record = MockCompanyRepository.companies.find(record => record.id === companyId);
        console.log("MockCompanyRepository getCompanyById", record);
        return record;
    }

}